import React from 'react';

// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
const Address = (props) => {
    let localCamper = JSON.parse(JSON.stringify(props.Camper));
    console.log("Value received", props, localCamper);

    const formatedAddress=()=>{
        let address ="";
        address = (localCamper.kid.address !== null && localCamper.kid.address)+", "+(localCamper.kid.city !== null && localCamper.kid.city)+(localCamper.kid.country !== null?", "+ localCamper.kid.country:"");
        address = address.replace(", ,", "");
        return address;

    }
    return (
        <Card border="light" className=" p-0 mb-4">

            <Card.Body className="">

                <Card.Title>Direccion  <Button variant="primary" size="sm" className="me-2">
                    <i className="fa fa-edit"/>
                </Button></Card.Title>
                <Card.Subtitle className="fw-normal">{formatedAddress()}</Card.Subtitle>
            </Card.Body>
        </Card>
    );
};

export default Address;