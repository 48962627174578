import React, { useEffect, useState } from 'react';

// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import api from '../../common/api';

const CampWith = (props) => {

    const [subscriptionSelected, setSubscriptionSelected] = useState(null);
    const [subscriptions, setSubscriptions] = useState([])
    const [state, setState] = useState([])
    const [currentYear, setCurrentYear] = useState(null);
    useEffect(() => {

        request();

    }, [])


    const request = async (subscription) => {
        try {
            let query = "";

            //Request the years first
            await requestCurrentYear();
            if (props.Camper) {
                query += "CamperId=" + props.Camper;
            }

            if (props.User) {

                query += "UserId=" + props.User;
            }

            if (subscription) {
                query += "&subscription=" + subscription;
            }

            const request = await api.getAction(
                "Reports/CampWith", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setState(response.response);
                //   state.SelectedFile.set(null);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    const requestCurrentYear = async () => {
        try {
            let query = "";



            const request = await api.getAction(
                "year/current", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setCurrentYear(response.response);
                
                await requestSubscriptions(response.response.id);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    const searchSubscription = async obj=>{
        setSubscriptionSelected(obj);
        await request(obj);
    }

    const requestSubscriptions = async ( year) => {
        try {
            let query ="YearId="+ year;
            
           

            const request = await api.getAction(
                "year/SubscriptionList", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setSubscriptions(response.response);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    return (
        <>


            <Card border="light" className=" p-0 mb-4">

                <Card.Body className="table-responsive">

                    <div className="row">
                        <div className="col">
                            <Card.Title className="d-inline">Prefiere acampar con</Card.Title>
                            <button onClick={() => {
                                window.print();
                            }}
                                className="btn btn-sm btn-primary hidden-print ms-2">
                                <i className="fas fa-print"></i>
                            </button>
                            <button type="button" className="btn btn-primary btn-sm hidden-print ms-2"
                                // onClick={exportExcel()}>
                               >
                               <i className="fas fa-file-excel"></i>
                            </button>
                        </div>
                        <div className="col text-right">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" >Programa</span>
                                </div>
                                <select className="form-control form-control-sm" value={subscriptionSelected}
                                onChange={(e)=>searchSubscription(e.target.value)}
                                >
                                    <option value="">-</option>
                                    {subscriptions && subscriptions.map((item, index)=>{
                                        return (<option key={index} value={item.id}>{item.name+ " "+ moment(item.date).format("MMM DD, YYYY")}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    Nombre
                                </th>
                                <th>
                                    Baiguatero ID
                                </th>
                                <th>Genero</th>
                                <th>
                                    Edad
                                </th>

                                <th>
                                    Curso
                                </th>

                                <th>Tamaño Camiseta</th>
                                <th>
                                    Colegio
                                </th>
                                <th>
                                    Nacimiento
                                </th>
                                <th>
                                    Prefiere acampar
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {state && state.map((item, index) => {
                                let name = item.lastName + " " + (item.secondLastName !== null ? item.secondLastName : "") + ", " + item.firstName + " " + (item.middleName !== null ? item.middleName : "");
                                name = name.replace(" ,", ",").trim();

                                let years = moment().diff(item.birthdate.substring(0, 10), "years", false);

                                return (<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className='text-uppercase'>{name}</td>
                                    <td>{item.baiguateroId}</td>
                                    <td>{item.gender && item.gender.name.substring(0, 1)}</td>
                                    <td>{years}</td>
                                    <td>{item.kidCampYears.filter(x => x.campYearId === currentYear.id)[0].grade}</td>

                                    <td>{item.kidCampYears.filter(x => x.campYearId === currentYear.id)[0].shirtSize && item.kidCampYears.filter(x => x.campYearId === currentYear.id)[0].shirtSize.name}</td>
                                    <td>{item.kidCampYears.filter(x => x.campYearId === currentYear.id)[0].school && item.kidCampYears.filter(x => x.campYearId === currentYear.id)[0].school.name}</td>
                                    <td>{item.birthdate && moment(item.birthdate).format("MMM DD")}</td>
                                    <td className='text-wrap'>{item.partnersNames}</td>
                                </tr>)
                            })}
                        </tbody>

                    </table>
                </Card.Body>
            </Card>
        </>
    );
}

export default CampWith;