import React from 'react';
import { Routes } from '../../routes';
// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
const UserProfileCard = (props) => {
    let localUser = JSON.parse(JSON.stringify(props.User));
    console.log("Value received", props, localUser);
    
    return (<>
        <Card border="light" className="text-center p-0 mb-4">
            {/* <div style={{ backgroundImage: `url(${ProfileCover})` }} className="profile-cover rounded-top" /> */}
            <Card.Body className="pb-5">
                {/* <Card.Img src={Profile1} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" /> */}
                <Card.Title>{localUser.firstName} <br />{localUser.lastName}   </Card.Title>
                <Card.Subtitle className="fw-normal">{localUser.email}</Card.Subtitle>
                
                <Button variant="primary" size="sm" className="me-2 d-none">
                    Editar
                </Button>
                <Button variant="primary" size="sm" className="me-2" onClick={()=>{
                    props.toggleChangePassword(localUser);
                }}>
                    Editar Contraseña
                </Button>
            </Card.Body>
        </Card>
      
        </>
    );
};

export default UserProfileCard;