import React, { useEffect, useState } from 'react'
import api from '../../common/api';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faPrint, faCloudUploadAlt, faEllipsisV, faPlus, faRocket, faTasks, faTrash, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Routes } from '../../routes';
import moment from 'moment-timezone';
import { NameRenderer } from '../../common/helpers';


const CamperList = props => {

    const [subscriptions, setSubscriptions] = useState([]);

    const [search, setSearch] = useState('');
    const [openSubscription, setOpenSubscription] = useState(null);

    const bindRemote = async () => {
        let query = "";

        if (search.length > 0) {
            query = `&search=${search.toLowerCase()}`;
        }
        let request = await api.getAction("campers/list", query);
        console.log("request response", request);
        if (request.data.status === "ok") {

            setSubscriptions(request.data.response)

        }
    }

    const paymentStatus = (subscription, payments) => {
        let totalCost = subscription.cost;
        let totalPayments = 0;
        payments.forEach(item => {
            totalPayments = totalPayments + item.amount;
            if (item.discount) {
                totalCost = totalCost - item.discount;
            }
        });

        let finalValue = totalCost - totalPayments;
        console.log(finalValue, totalCost, totalPayments);
        if (finalValue === totalCost) {
            return 'none';
        } else if (finalValue > 0) {
            return 'pending';
        } else {
            return 'paid';
        }
    }


    useEffect(() => {
        bindRemote();
    }, [])
    useEffect(() => {
        console.log("the state got updated", subscriptions);
    }, [subscriptions]);
    return (
        <>
            {/* SEARCH */}
            <div className="card shadow-sm border-light mb-4">
                <div className='card-body p-1 py-2'>
                    <div className='row mx-0'>
                        <div className='col'>
                            <div className='input-group'>
                                <div className='input-group-text'>
                                    <i className="fas fa-search"></i>
                                </div>
                                <input type="text" className='form-control'
                                    placeholder='Nombre, apellido, id baiguate'
                                    value={search}
                                    onKeyUp={async e => {
                                        console.log(e);
                                        if (e.key === 'Enter' || e.keyCode === 13) {
                                            await bindRemote();
                                        }
                                    }}
                                    onChange={e => {
                                        setSearch(e.target.value)
                                    }} />
                                <div className='input-group-append'>
                                    <button className='btn btn-primary'>
                                        <i className='fas fa-filter'></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* END SEARCH */}
            <div className="accordion accordion-flush" >
                {subscriptions && subscriptions.map((currentSubscription, index) => {
                    console.log("enter on subscription ", currentSubscription);
                    return (
                        <div className="card shadow-sm border-light mb-2" key={index}>
                            <div className="card-body p-1">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" >
                                        <button className={(openSubscription === currentSubscription.id &&
                                            openSubscription === null ? "" : " collapsed") + " accordion-button "} type="button"
                                            onClick={() => {
                                                if (currentSubscription.id === openSubscription) {
                                                    setOpenSubscription(null);
                                                } else {
                                                    setOpenSubscription(currentSubscription.id);
                                                }

                                            }}
                                        >
                                            {currentSubscription.name} {moment(currentSubscription.date).format("MMM DD, YYYY")} ({currentSubscription.campers.length})
                                        </button>
                                    </h2>
                                    <div className={(openSubscription === currentSubscription.id || openSubscription === null ? "" : " collapse") + " accordion-collapse "}>
                                        <div className="accordion-body  table-responsive">
                                            <table className="table w-100">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombre</th>
                                                        <th>ID</th>
                                                        <th className="text-wrap text-center">Formulario médico</th>
                                                        <th>T-Shirt</th>
                                                        <th>Edad</th>
                                                        <th>Curso</th>
                                                        <th>Descargo</th>
                                                        <th>Salida</th>
                                                        <th className="text-wrap text-center" >Estatus pago</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentSubscription.campers.map((item, indexSubItem) => {

                                                        let years = moment().diff(item.kid.birthdate.substring(0, 10), "years", false);
                                                        let camperPaymentStatus = paymentStatus(currentSubscription, item.subscriptionPayments);
                                                        return (
                                                            <tr key={indexSubItem}>
                                                                <td>
                                                                    {indexSubItem + 1}
                                                                    {item.subscriptionPayments.Count === 0 ?
                                                                        <i className="fa fa-circle"></i> : null}
                                                                </td>
                                                                <td className="clickable text-wrap" onClick={() => {

                                                                    props.history.push(Routes.CamperDetails.clean + item.id)
                                                                }}>
                                                                    <span className="text-uppercase">{NameRenderer(item.kid)}</span>

                                                                </td>
                                                                <td >

                                                                    {item.kid.baiguateroId}
                                                                </td>
                                                                <td className="text-center">

                                                                    {item.formularioMedicoRespuesta ? "Si" : "-"}
                                                                </td>
                                                                <td className="text-wrap">
                                                                    {item.shirtsize !== null ?
                                                                        item.shirtsize.name
                                                                        : null}
                                                                </td>
                                                                <td className="text-wrap" >
                                                                    {/* {item.kid.birthdate ?
                                      moment(item.kid.birthdate).format("MMM DD") + " (" + years + " años)"
                                      : null} */}
                                                                    {item.kid.birthdate ?
                                                                        years
                                                                        : null}
                                                                </td>
                                                                <td className="text-wrap">
                                                                    {item.grade}
                                                                </td>

                                                                <td className="text-wrap">

                                                                    {item.termsAndConditions ? "Si" : "-"}
                                                                </td>
                                                                <td className="text-wrap">
                                                                    {item.kid.city}
                                                                </td>
                                                                <td className='text-center'>
                                                                    {camperPaymentStatus === 'none' ?
                                                                        <i className='fa fa-circle text-danger'></i>
                                                                        : camperPaymentStatus === 'pending' ?
                                                                            <i className='fa fa-circle text-warning'></i>
                                                                            : <i className='fa fa-circle text-success'></i>}
                                                                </td>
                                                                <td className=" text-right">
                                                                    <Dropdown className="">
                                                                        <Dropdown.Toggle as={Button} size="sm" className="me-2">
                                                                            <FontAwesomeIcon icon={faEllipsisV} className="me-2" />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                                                                            <Dropdown.Item className="fw-bold" onClick={() => {
                                                                                props.history.push(Routes.CamperDetails.clean + item.id)
                                                                            }}>
                                                                                <FontAwesomeIcon icon={faTasks} className="me-2" /> Ver detalle
                                                                            </Dropdown.Item>

                                                                            <Dropdown.Item className="fw-bold">
                                                                                <FontAwesomeIcon icon={faPrint} className="me-2" /> Imprimir
                                                                            </Dropdown.Item>

                                                                            <Dropdown.Divider />

                                                                            <Dropdown.Item className="fw-bold">
                                                                                <FontAwesomeIcon icon={faTrash} className="text-danger me-2" /> Eliminar
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>

                                                                </td>

                                                            </tr>);
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </>
    )
}

export default CamperList;