import React, { useEffect, useState } from 'react';

// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import api from '../../common/api';



import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { v4 as uuidv4 } from 'uuid';
import AddEditExtraExpenses from './AddEditExtraExpenses';
const ExtraExpenses = (props) => {
    
    const [state, setState] = useState([]);
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selectedItem, setselectedItem] = useState(null);

    const toggleAddEdit =async  (itemSelected, reload) => {
        if (!itemSelected) {
            itemSelected = {
                id: 0,
                name: "",
                cost: 0,
                campYearId:props.currentYear.id

            }
        }

        setselectedItem(itemSelected);
        setShowAddEdit(!showAddEdit);

        if(reload){
           await  request();
        }
    }
    useEffect(() => {

        request();

    }, [props.Camper, props.User])

    const deleteConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.name + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await api.getAction("extraExpenses/Delete", "id="+ obj.id);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }

    const request = async () => {
        try {
            let query = "";
            if (props.Camper) {
                query += "CamperId=" + props.Camper;
            }

            if (props.User) {

                query += "UserId=" + props.User;
            }
            const request = await api.getAction(
                "ExtraExpenses/List", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setState(response.response);
                //   state.SelectedFile.set(null);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    return (
        <>
            {showAddEdit ?
                <AddEditExtraExpenses model={selectedItem} toggle={toggleAddEdit} show={showAddEdit} /> :
                null}

            <Card border="light" className=" p-0 mb-4">

                <Card.Body className="table-responsive">

                    <Card.Title>Gastos Extra del año
                        <button className='btn btn-primary btn-sm mx-2' type="button"
                            onClick={() => {
                                toggleAddEdit();
                            }}>
                            <i className="fa fa-plus" /> Agregar
                        </button>
                    </Card.Title>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Año</th>
                                <th>Costo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.map((item, index) => {
                                return (<tr key={index}>
                                    <td>{item.name}</td>
                                 
                                    <td>{item.campYear ? moment(item.campYear.year).format("YYYY"): ""}</td>

                                    <td>{item.cost}</td>
                                    <td className='text-right'>
                                        <div className='btn-group'>
                                            <button className='btn btn-sm btn-primary' type="button " onClick={() => {
                                                toggleAddEdit(item)
                                            }}>
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            <button className='btn btn-sm btn-danger' type="button " onClick={() => {
                                                deleteConfirm(item)
                                            }}>
                                                <i className='fa fa-trash'></i>
                                            </button>
                                            
                                        </div>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        </>
    );
};

export default ExtraExpenses;