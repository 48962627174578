
import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faPrint, faCloudUploadAlt, faEllipsisV, faPlus, faRocket, faTasks, faTrash, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { Routes } from "../../routes";
import IslogedIn from "../../common/IsLogedIn";
import api from "../../common/api";
import { useState } from "@hookstate/core";
import moment from "moment";
import { FilterList, NameRenderer } from "../../common/helpers";
import CamperList from "./CamperList";


const Index = (props) => {
  
    
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/"><i className="fa fa-home"></i></a></li>

                    <li className="breadcrumb-item"><a href={Routes.Campers.path}>Acampantes</a></li>
                    
                </ol>
            </nav>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            

            </div>

            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                  <CamperList history={props.history}/>
                </Col>

             
            </Row>

        </>
    );
};


export default Index;