import { Form } from '@themesberg/react-bootstrap';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import formularioMedico from '../../assets/evaluacionMedica2023.docx';
import descargo from '../../assets/DESCARGO2022.pdf';
import listadoRopa from '../../assets/listadoRopaCampamentoBaiguate2022.pdf';

//Validation
const validationSchema = yup.object({
    firstName: yup.string().required(),


});



const Final = props => {

    const handleSubmit = async (obj, event) => {
        props.setActiveStep(props.step + 1)

    };

    const formik = useFormik({
        initialValues: props.model ? props.model :
            {
                firstName: ""
            },
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (<>
    <Form onSubmit={formik.handleSubmit}>
        <div className="card shadow-sm mt-2">
            <div className="card-body">
                <div className="row ">
                    <p>&nbsp;</p>
                    <h3 className="text-uppercase margin">
                        <small>
                            Contacto de emergencia (Que no sean los padres)
                        </small>
                    </h3>
                    <hr />


                    <div className="col-md-6 col-xs-6">
                        <label >Nombre</label>

                        <input className="form-control text-box single-line" 
                            name="emergencyContactName"
                                value={formik.values.emergencyContactName ? formik.values.emergencyContactName : ""} 
                                onChange={formik.handleChange} />
                            {formik.errors.emergencyContactName ? <div className="text-danger text-sm">{formik.errors.emergencyContactName}</div> : null}
                    </div>

                    <div className="col-md-6 col-xs-6">
                        <label className="control-label" for="EmergencyContactRelationship">Relaci&#243;n con el acampante</label>


                        <input className="form-control text-box single-line" 
                            name="emergencyContactRelationship"
                                value={formik.values.emergencyContactRelationship ? formik.values.emergencyContactRelationship : ""} 
                                onChange={formik.handleChange} />
                            {formik.errors.emergencyContactRelationship ? <div className="text-danger text-sm">{formik.errors.emergencyContactRelationship}</div> : null}
                    </div>

                    <div className="col-md-6 col-xs-6">
                        <label className="control-label" for="EmergencyContactPhone">Tel&#233;fono</label>

                        <input className="form-control text-box single-line" 
                            name="emergencyContactPhone"
                                value={formik.values.emergencyContactPhone ? formik.values.emergencyContactPhone : ""} 
                                onChange={formik.handleChange} />
                            {formik.errors.emergencyContactPhone ? <div className="text-danger text-sm">{formik.errors.emergencyContactPhone}</div> : null}
                    </div>

                    <div className="col-md-6 col-xs-6">
                        <label className="control-label" for="EmergencyContactPhone2">Celular</label>

                        <input className="form-control text-box single-line" 
                            name="emergencyContactPhone2"
                                value={formik.values.emergencyContactPhone2 ? formik.values.emergencyContactPhone2 : ""} 
                                onChange={formik.handleChange} />
                            {formik.errors.emergencyContactPhone2 ? <div className="text-danger text-sm">{formik.errors.emergencyContactPhone2}</div> : null}
                    </div>
                    <div className="col-xs-12">
                        <label className="control-label" for="WhoCanTakeTheKidOut">Nombre de personas autorizadas a sacar a pasear o buscar el acampante al campamento</label>

                        <textarea className="form-control" cols="20" 
                          name="whoCanTakeTheKidOut"
                          value={formik.values.whoCanTakeTheKidOut ? formik.values.whoCanTakeTheKidOut : ""} 
                          onChange={formik.handleChange}
                        rows="2">
                        </textarea>
                        {formik.errors.whoCanTakeTheKidOut ? <div className="text-danger text-sm">{formik.errors.whoCanTakeTheKidOut}</div> : null}
                    </div>



                    <div className="col-6 mt-4">
                        <label className="control-label">
                            Descargar Formulario Medico &amp; Términos y Condiciones
                        </label><br />
                        <a href={formularioMedico} className="btn btn-primary">Formulario Médico</a>&nbsp;
                        <a href={descargo} className="btn btn-primary">Términos y Condiciones</a>
                        <blockquote>
                            <small>Ambos formularios deben imprimirse, completarse y firmarse. Luego de completarse pueden enviarlo via la plataforma, en el perfil del acampante.</small>
                        </blockquote>
                    </div>
                    <div className="col-6 mt-4">
                    <label className="control-label">
                           Listado de ropa para llevar al campamento
                        </label><br />
                        <a href={listadoRopa} className="btn btn-primary">Descargar listado</a>&nbsp;
                      
                    </div>

                    <div className="col-12 my-4">
                        <div className="callout callout-info bg-primary text-light p-4">
                            <h4>
                                CONSENTIMIENTO
                            </h4>
                            <p>
                                Quien suscribe autoriza al personal de Baiguate a obtener tratamiento médico de emergencia necesario para mi hijo/hija en el entendido de que la familia será notificada a la mayor brevedad.
                            </p>
                            <p>
                                Certifico que la información de este Formulario Médico es correcta y completa a mi leal saber y entender. Entiendo que proveer información incorrecta puede ser dañina para la salud y bienestar de mi hijo/hija.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-xs-12 text-right">
                        <button className="btn btn-default" type="button" onClick={() => props.setActiveStep(props.step - 1)}>Retroceder</button>
                        <button className="btn btn-primary" type="submit" >Finalizar</button>
                    </div>
                </div>
            </div>
        </div>
        </Form>
    </>)
}

export default Final;