import React, { useState } from 'react';

// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import AddEditEducation from './AddEditEducation';
const Education = (props) => {
    let localCamper = JSON.parse(JSON.stringify(props.Camper));
    console.log("Value received", props, localCamper);
    const [showEdit, setShowEdit] = useState(false);
    const toggleEdit = () => {
        setShowEdit(!showEdit);
    }
    return (
        <>
            {showEdit ? <AddEditEducation toggle={toggleEdit} model={localCamper}></AddEditEducation> : null}
            <Card border="light" className=" p-0 mb-4">

                <Card.Body className="">

                    <Card.Title>Educación  <Button variant="primary" size="sm" className="me-2">
                        <button className='btn btn-primary btn-sm' onClick={() => {
                            toggleEdit()
                        }}>
                            <i className="fa fa-edit" />
                        </button>

                    </Button></Card.Title>
                    <Card.Subtitle className="fw-normal"><b>Colegio:</b> {localCamper && localCamper.school ? localCamper.school.name : "-"} <b>Curso: </b>{localCamper.grade}</Card.Subtitle>
                </Card.Body>
            </Card>
        </>
    );
};

export default Education;