import { Form } from '@themesberg/react-bootstrap';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import api from '../../common/api';

const Health = props => {
    const validationSchema = yup.object({

        abdomenApetito: yup.string().nullable().label("Apetito").required(),
        abdomenConstipacion: yup.string().nullable().label("Constipación").required(),
        abdomenDiarrea: yup.string().nullable().label("Diarrea").required(),
        traumaCraneal: yup.string().nullable().label("Trauma Craneal").required(),
        fracturaOtorcedura: yup.string().nullable().label("Fractura o Torceduras").required(),
        hospitalizaciones: yup.string().nullable().label("Hospitalicaciones").required(),
        cirugias: yup.string().nullable().label("Cirugias").required(),
        enfermedadesCronicas: yup.string().nullable().label("Enfermedades Cronicas").required(),
        diabetes: yup.string().nullable().label("Diabetes").required(),
        asma: yup.string().nullable().label("Asma").required(),
        inmunoterapia: yup.string().nullable().label("Inmunoterapia").required(),
        tratamientoHormonal: yup.string().nullable().label("Tratamiento Hormonal").required(),
        //otrosTratamientos: yup.string().nullable().label("").required(),
        reaccionAlergicaMedicamentos: yup.string().nullable().label("Reacción alergica a medicamentos").required(),
        reaccionAlergicaAmbiente: yup.string().nullable().label("Reacción alergica ambiente").required(),
        alimentos: yup.string().nullable().label("Alergia Alimentos").required(),
        ojosVisual: yup.string().nullable().label("Vista").required(),
        ojosLentes: yup.string().nullable().label("Lentes").required(),
        oidosAuditivo: yup.string().nullable().label("Auditivo").required(),
        oidoInfecciones: yup.string().nullable().label("Infecciones").required(),
        narizRinitis: yup.string().nullable().label("Rinitis").required(),
        narizSangra: yup.string().nullable().label("Nariz Sangrante").required(),
        garganta: yup.string().nullable().label("Garganta").required(),
        corazon: yup.string().nullable().label("Corazón").required(),
        pulmones: yup.string().nullable().label("Pulmones").required(),
        abdomenDolor: yup.string().nullable().label("Dolor abdomen").required(),
        abdomenPeso: yup.string().nullable().label("Peso").required(),
        rinonMolestiaOrinar: yup.string().nullable().label("Riñon molestia la orinar").required(),
        rinonMojaCama: yup.string().nullable().label("Moja la cama").required(),
        rinonSangreOrinar: yup.string().nullable().label("Sangre al Orinar").required(),
        rinonInfeccion: yup.string().nullable().label("Infección").required(),
        piel: yup.string().nullable().label("Piel").required(),
        neurologicoMigrana: yup.string().nullable().label("Migraña").required(),
        neurologicoConvulsiones: yup.string().nullable().label("Convulsiones").required(),
        neurologicoDesmayos: yup.string().nullable().label("Desmayos").required(),
        neurologicoDolorDeCabeza: yup.string().nullable().label("Dolor de Cabeza").required(),
        relacionesAsisteEscuela: yup.string().nullable().label("Asiste Escuela").required(),
        relacionesAnsiedadPanico: yup.string().nullable().label("Ansiedad Panico").required(),
        relacionesFiguraAutoridad: yup.string().nullable().label("Figura Autoridad").required(),
        relacionesAmigos: yup.string().nullable().label("Amigos").required(),
        relacionesHermanos: yup.string().nullable().label("Hermanos").required(),
        relacionesSueno: yup.string().nullable().label("Sueño").required(),
        relacionesTemperamentoExplosivo: yup.string().nullable().label("Temperamento Explosivo").required(),
        relaccionesDeficitAtencion: yup.string().nullable().label("Deficit de Atención").required(),
        relacionesFobia: yup.string().nullable().label("Fobia").required(),
        // formularioMedicoRespuesta: yup.string().nullable().label("").required(),
        //  formularioMedicoDolenciaNoContemplada: yup.string().nullable().label("").required(),
        relacionesImpulsividad: yup.string().nullable().label("Impulsividad").required(),


    });

    const [modelPediatra, setModelPediatra] = useState({
        name : '',
        email: '',
        phone:'',
        phone2 :'',
        id: 0
    })

    const [insurances, setInsurances] = useState([]);
    const insurancesRequest = async () => {
        try {
            let query = "";

            const request = await api.getAction(
                "insurances/list", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setInsurances(response.response);
                //   state.SelectedFile.set(null);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    const handleSubmit = async (obj, event) => {

        let requestPediatrician = await api.postAction(
            "campers/editPediatrician?kidId="+model.kidId, modelPediatra
        );
        let request = await api.postAction(
            "campers/edit", obj
        );

        if (request.data.status === "ok") {
            props.saveApplication(request.data.response);
            props.setActiveStep(props.step + 1)
        }


    };

    const [model, setModel] = useState({});

    const formik = useFormik({
        initialValues: model,
        validationSchema,
        onSubmit: handleSubmit,
    });

    const requestRecord = async () => {
        let query = "kidid=" + props.model.id
        let request = await api.getAction("campers/exist", query);
        if (request.data.status === "ok") {
            let result = request.data.response;
            let obj = result;
            setModel(obj);
            if(result.kid.pediatrician){
                setModelPediatra(result.kid.pediatrician);
            }
            formik.setValues(obj)
        }
    }
    useEffect(() => {
        insurancesRequest();

        requestRecord();
    }, []);

    return (<>
        <Form onSubmit={formik.handleSubmit}>
            <div className="card shadow-sm mt-2">
                <div className="card-body">
                    <div className="row margin table-responsive">
                        <div className='col-12 pt-4'>
                            <h3>El niño/niña ha tenido en el último año:</h3>
                            <hr />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Trauma Craneal</label>
                            <select className="form-control"
                                name="traumaCraneal"
                                value={formik.values.traumaCraneal ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.traumaCraneal ? <div className="text-danger text-sm">{formik.errors.traumaCraneal}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Fracturas o Torceduras</label>
                            <select className="form-control"
                                name="fracturaOtorcedura"
                                value={formik.values.fracturaOtorcedura ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.fracturaOtorcedura ? <div className="text-danger text-sm">{formik.errors.fracturaOtorcedura}</div> : null}
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Hospitalizaciones</label>
                            <select className="form-control"
                                name="hospitalizaciones"
                                value={formik.values.hospitalizaciones ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.hospitalizaciones ? <div className="text-danger text-sm">{formik.errors.hospitalizaciones}</div> : null}
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Cirugias</label>
                            <select className="form-control"
                                name="cirugias"
                                value={formik.values.cirugias ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.cirugias ? <div className="text-danger text-sm">{formik.errors.cirugias}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>El niño/niña padece de:</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Enfermedades Crónicas o Congénitas</label>
                            <select className="form-control"
                                name="enfermedadesCronicas"
                                value={formik.values.enfermedadesCronicas ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.enfermedadesCronicas ? <div className="text-danger text-sm">{formik.errors.enfermedadesCronicas}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Diabetes</label>
                            <select className="form-control"
                                name="diabetes"
                                value={formik.values.diabetes ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.diabetes ? <div className="text-danger text-sm">{formik.errors.diabetes}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Asma</label>
                            <select className="form-control"
                                name="asma"
                                value={formik.values.asma ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.asma ? <div className="text-danger text-sm">{formik.errors.asma}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Medicamentos a administrar en su estadía:</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Inmunoterapia</label>
                            <select className="form-control"
                                name="inmunoterapia"
                                value={formik.values.inmunoterapia ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.inmunoterapia ? <div className="text-danger text-sm">{formik.errors.inmunoterapia}</div> : null}
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Tratamiento Hormonal</label>
                            <select className="form-control"
                                name="tratamientoHormonal"
                                value={formik.values.tratamientoHormonal ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {formik.errors.tratamientoHormonal ? <div className="text-danger text-sm">{formik.errors.tratamientoHormonal}</div> : null}
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Otros</label>
                            <textarea className="form-control" cols="20" name="otrosTratamientos" placeholder="Dosis/Frecuencia" row="4" rows="2"

                                value={formik.values.otrosTratamientos ?? ""} onChange={formik.handleChange}
                            ></textarea>

                            {formik.errors.otrosTratamientos ? <div className="text-danger text-sm">{formik.errors.otrosTratamientos}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Reacción Alérgica (Especificar):</h3>
                            <hr />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Medicamentos?</label>
                            <select className="form-control"
                                name="reaccionAlergicaMedicamentos"
                                value={formik.values.reaccionAlergicaMedicamentos ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.reaccionAlergicaMedicamentos ? <div className="text-danger text-sm">{formik.errors.reaccionAlergicaMedicamentos}</div> : null}
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Ambiente?</label>
                            <select className="form-control"
                                name="reaccionAlergicaAmbiente"
                                value={formik.values.reaccionAlergicaAmbiente ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.reaccionAlergicaAmbiente ? <div className="text-danger text-sm">{formik.errors.reaccionAlergicaAmbiente}</div> : null}
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Alimentos?</label>
                            <select className="form-control"
                                name="alimentos"
                                value={formik.values.alimentos ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.alimentos ? <div className="text-danger text-sm">{formik.errors.alimentos}</div> : null}
                        </div>
                        <div className='col-12 pt-4'>
                            <h3>Ojos (Especificar):</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Algún problema Visual?</label>
                            <select className="form-control"
                                name="ojosVisual"
                                value={formik.values.ojosVisual ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.ojosVisual ? <div className="text-danger text-sm">{formik.errors.ojosVisual}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>El niño/niña usa lentes?</label>
                            <select className="form-control"
                                name="ojosLentes"
                                value={formik.values.ojosLentes ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.ojosLentes ? <div className="text-danger text-sm">{formik.errors.ojosLentes}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Oidos (Especificar):</h3>
                            <hr />
                        </div>
                        {/* 
                    <div className="col-12 col-md-3">
                        <label>Algún problema Auditivo?</label>
                        <select className="form-control"
                            name="oidosAuditivo"
                            value={formik.values.oidosAuditivo ?? ""} onChange={formik.handleChange}
                        >
                            <option value="">-</option>
                            <option value="false">No</option>
                            <option value="true">Si</option>
                        </select>

                        {formik.errors.oidosAuditivo ? <div className="text-danger text-sm">{formik.errors.oidosAuditivo}</div> : null}
                    </div> */}

                        <div className="col-12 col-md-3">
                            <label>Algún problema Auditivo?</label>
                            <select className="form-control"
                                name="oidosAuditivo"
                                value={formik.values.oidosAuditivo ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.oidosAuditivo ? <div className="text-danger text-sm">{formik.errors.oidosAuditivo}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Tres o más infecciones en el último año?</label>
                            <select className="form-control"
                                name="oidoInfecciones"
                                value={formik.values.oidoInfecciones ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.oidoInfecciones ? <div className="text-danger text-sm">{formik.errors.oidoInfecciones}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Nariz:</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Rinitis Alérgica?</label>
                            <select className="form-control"
                                name="narizRinitis"
                                value={formik.values.narizRinitis ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.narizRinitis ? <div className="text-danger text-sm">{formik.errors.narizRinitis}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Sangra frecuentemente por la nariz?</label>
                            <select className="form-control"
                                name="narizSangra"
                                value={formik.values.narizSangra ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.narizSangra ? <div className="text-danger text-sm">{formik.errors.narizSangra}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Garganta:</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Tres o mas infecciones en el último año que ha requerido antobiótico?</label>
                            <select className="form-control"
                                name="garganta"
                                value={formik.values.garganta ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.garganta ? <div className="text-danger text-sm">{formik.errors.garganta}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Corazón:</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Alguna vez le han dicho que su hijo/hija tiene algún defecto congénito del corazón que requiere uso de medicamento?</label>
                            <select className="form-control"
                                name="corazon"
                                value={formik.values.corazon ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.corazon ? <div className="text-danger text-sm">{formik.errors.corazon}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Pulmones:</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Tos crónica</label>
                            <select className="form-control"
                                name="pulmones"
                                value={formik.values.pulmones ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.pulmones ? <div className="text-danger text-sm">{formik.errors.pulmones}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Abdomen:</h3>
                            <hr />
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Dificultad con el apetito?</label>
                            <select className="form-control"
                                name="abdomenApetito"
                                value={formik.values.abdomenApetito ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.abdomenApetito ? <div className="text-danger text-sm">{formik.errors.abdomenApetito}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Dolor abdominal frecuente</label>
                            <select className="form-control"
                                name="abdomenDolor"
                                value={formik.values.abdomenDolor ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.abdomenDolor ? <div className="text-danger text-sm">{formik.errors.abdomenDolor}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Diarrea o vómitos frecuentes?</label>
                            <select className="form-control"
                                name="abdomenDiarrea"
                                value={formik.values.abdomenDiarrea ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.abdomenDiarrea ? <div className="text-danger text-sm">{formik.errors.abdomenDiarrea}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Constipación?</label>
                            <select className="form-control"
                                name="abdomenConstipacion"
                                value={formik.values.abdomenConstipacion ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.abdomenConstipacion ? <div className="text-danger text-sm">{formik.errors.abdomenConstipacion}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Pérdida de peso de más de 15 lbs en el último año?</label>
                            <select className="form-control"
                                name="abdomenPeso"
                                value={formik.values.abdomenPeso ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.abdomenPeso ? <div className="text-danger text-sm">{formik.errors.abdomenPeso}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Riñon:</h3>
                            <hr />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Se queja  de molestia al orinar?</label>
                            <select className="form-control"
                                name="rinonMolestiaOrinar"
                                value={formik.values.rinonMolestiaOrinar ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.rinonMolestiaOrinar ? <div className="text-danger text-sm">{formik.errors.rinonMolestiaOrinar}</div> : null}
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Moja la cama?</label>
                            <select className="form-control"
                                name="rinonMojaCama"
                                value={formik.values.rinonMojaCama ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.rinonMojaCama ? <div className="text-danger text-sm">{formik.errors.rinonMojaCama}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Sangre en la orina recientemente?</label>
                            <select className="form-control"
                                name="rinonSangreOrinar"
                                value={formik.values.rinonSangreOrinar ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.rinonSangreOrinar ? <div className="text-danger text-sm">{formik.errors.rinonSangreOrinar}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Infección urinaria en el último año?</label>
                            <select className="form-control"
                                name="rinonInfeccion"
                                value={formik.values.rinonInfeccion ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.rinonInfeccion ? <div className="text-danger text-sm">{formik.errors.rinonInfeccion}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Piel:</h3>
                            <hr />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Eczema o Dermatitis atópica?</label>
                            <select className="form-control"
                                name="piel"
                                value={formik.values.piel ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.piel ? <div className="text-danger text-sm">{formik.errors.piel}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Neurológico:</h3>
                            <hr />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Migraña?</label>
                            <select className="form-control"
                                name="neurologicoMigrana"
                                value={formik.values.neurologicoMigrana ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.neurologicoMigrana ? <div className="text-danger text-sm">{formik.errors.neurologicoMigrana}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Convulsiones?</label>
                            <select className="form-control"
                                name="neurologicoConvulsiones"
                                value={formik.values.neurologicoConvulsiones ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.neurologicoConvulsiones ? <div className="text-danger text-sm">{formik.errors.neurologicoConvulsiones}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Desmayos?</label>
                            <select className="form-control"
                                name="neurologicoDesmayos"
                                value={formik.values.neurologicoDesmayos ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.neurologicoDesmayos ? <div className="text-danger text-sm">{formik.errors.neurologicoDesmayos}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Dolores de cabeza frecuentes?</label>
                            <select className="form-control"
                                name="neurologicoDolorDeCabeza"
                                value={formik.values.neurologicoDolorDeCabeza ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.neurologicoDolorDeCabeza ? <div className="text-danger text-sm">{formik.errors.neurologicoDolorDeCabeza}</div> : null}
                        </div>

                        <div className='col-12 pt-4'>
                            <h3>Relaciones Interpersonales /  Tiene su hijo/hija problemas con:</h3>
                            <hr />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Asistencia a la escuela?</label>
                            <select className="form-control"
                                name="relacionesAsisteEscuela"
                                value={formik.values.relacionesAsisteEscuela ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesAsisteEscuela ? <div className="text-danger text-sm">{formik.errors.relacionesAsisteEscuela}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Ansiedad o ataques de pánico?</label>
                            <select className="form-control"
                                name="relacionesAnsiedadPanico"
                                value={formik.values.relacionesAnsiedadPanico ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesAnsiedadPanico ? <div className="text-danger text-sm">{formik.errors.relacionesAnsiedadPanico}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Padres/Figuras de autoridad?</label>
                            <select className="form-control"
                                name="relacionesFiguraAutoridad"
                                value={formik.values.relacionesFiguraAutoridad ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesFiguraAutoridad ? <div className="text-danger text-sm">{formik.errors.relacionesFiguraAutoridad}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Compañeros o amigos?</label>
                            <select className="form-control"
                                name="relacionesAmigos"
                                value={formik.values.relacionesAmigos ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesAmigos ? <div className="text-danger text-sm">{formik.errors.relacionesAmigos}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Hermanos?</label>
                            <select className="form-control"
                                name="relacionesHermanos"
                                value={formik.values.relacionesHermanos ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesHermanos ? <div className="text-danger text-sm">{formik.errors.relacionesHermanos}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Sueño?</label>
                            <select className="form-control"
                                name="relacionesSueno"
                                value={formik.values.relacionesSueno ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesSueno ? <div className="text-danger text-sm">{formik.errors.relacionesSueno}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Temperamento explosivo?</label>
                            <select className="form-control"
                                name="relacionesTemperamentoExplosivo"
                                value={formik.values.relacionesTemperamentoExplosivo ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesTemperamentoExplosivo ? <div className="text-danger text-sm">{formik.errors.relacionesTemperamentoExplosivo}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Impulsividad?</label>
                            <select className="form-control"
                                name="relacionesImpulsividad"
                                value={formik.values.relacionesImpulsividad ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesImpulsividad ? <div className="text-danger text-sm">{formik.errors.relacionesImpulsividad}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Deficit de atención y/o hiperactividad?</label>
                            <select className="form-control"
                                name="relaccionesDeficitAtencion"
                                value={formik.values.relaccionesDeficitAtencion ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relaccionesDeficitAtencion ? <div className="text-danger text-sm">{formik.errors.relaccionesDeficitAtencion}</div> : null}
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Fobias?</label>
                            <select className="form-control"
                                name="relacionesFobia"
                                value={formik.values.relacionesFobia ?? ""} onChange={formik.handleChange}
                            >
                                <option value="">-</option>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>

                            {formik.errors.relacionesFobia ? <div className="text-danger text-sm">{formik.errors.relacionesFobia}</div> : null}
                        </div>
                        <div className="col-12">
                            <label>En caso de alguna respuesta afirmativa en las preguntas anteriores, favor explicar detalladamente:</label>
                            <textarea className="form-control" cols="20"

                                value={formik.values.formularioMedicoRespuesta ?? ""} onChange={formik.handleChange}
                                name="formularioMedicoRespuesta" rows="5"></textarea>
                            {formik.errors.formularioMedicoRespuesta ? <div className="text-danger text-sm">{formik.errors.formularioMedicoRespuesta}</div> : null}
                        </div>
                        <div className="col-12">
                            <label>Su hijo/hija tiene alguna otra enfermedad o dolencia no indicada en las preguntas anteriores? Si ha indicado afirmativo, favor explicar e indicar tratamiento indicado?</label>
                            <textarea className="form-control" cols="20"

                                value={formik.values.formularioMedicoDolenciaNoContemplada ?? ""} onChange={formik.handleChange}
                                name="formularioMedicoDolenciaNoContemplada" rows="5"></textarea>
                            {formik.errors.formularioMedicoDolenciaNoContemplada ? <div className="text-danger text-sm">{formik.errors.formularioMedicoDolenciaNoContemplada}</div> : null}
                        </div>

                    </div>
                    <div className="row ">

                        <div className="form-group ">

                            <p>&nbsp;</p>
                            <h3 className="text-uppercase margin">
                                <small>
                                    Pediatra
                                </small>
                            </h3>
                            <hr />
                        </div>
                        <input type="hidden" id="PediatricianID" name="PediatricianID" value="0" />

                        <div className="col-md-6 col-xs-6">
                            <label className="control-label">Nombre</label>

                            <input className="form-control"
                                type="text"
                                name="name"
                                value={modelPediatra.name ?? ""}
                                onChange={e=>{
                                    setModelPediatra({
                                        ...modelPediatra,
                                        name : e.target.value
                                    })
                                }} />
                            {formik.errors.name ? <div className="text-danger text-sm">{formik.errors.name}</div> : null}
                        </div>
                        <div className="col-md-6 col-xs-6">
                            <label className="control-label">Tel&#233;fono</label>
                            <input name="phone" type="text" className='form-control'
                              value={modelPediatra.phone ?? ""}
                              onChange={e=>{
                                  setModelPediatra({
                                      ...modelPediatra,
                                      phone : e.target.value
                                  })
                              }} />
                            {formik.errors.phone ? <div className="text-danger text-sm">{formik.errors.phone}</div> : null}

                        </div>

                        <div className="col-md-6 col-xs-6">
                            <label className="control-label">Tel&#233;fono Oficina</label>
                            <input name="phone2" type="text" className='form-control'
                                value={modelPediatra.phone2 ?? ""}
                                onChange={e=>{
                                    setModelPediatra({
                                        ...modelPediatra,
                                        phone2 : e.target.value
                                    })
                                }} />
                            {formik.errors.phone2 ? <div className="text-danger text-sm">{formik.errors.phone2}</div> : null}

                        </div>


                        <div className="col-md-6 col-xs-6">
                            <label className="control-label">E-Mail</label>

                            <input name="email" type="email" className='form-control'
                               value={modelPediatra.email ?? ""}
                               onChange={e=>{
                                   setModelPediatra({
                                       ...modelPediatra,
                                       email : e.target.value
                                   })
                               }} />
                            {formik.errors.email ? <div className="text-danger text-sm">{formik.errors.email}</div> : null}
                        </div>

                        <p>&nbsp;</p>
                        <h3 className="text-uppercase ">
                            <small>
                                Seguro
                            </small>
                        </h3>

                        <hr />

                        <div className="col-md-6 col-xs-6">
                            <label >Aseguradora </label>
                            <select className="form-control"
                                name="assuranceCompanyId"
                                value={formik.values.assuranceCompanyId ?? ""}
                                onChange={formik.handleChange}>
                                <option value="">-</option>
                                {insurances && insurances.map((item, index) => {
                                    return (<option value={item.id} key={index}>{item.name}</option>)
                                })}
                            </select>

                            {formik.errors.assuranceCompanyId ? <div className="text-danger text-sm">{formik.errors.assuranceCompanyId}</div> : null}

                        </div>

                        <div className="col-md-6 col-xs-6">
                            <label className="control-label" for="InsurancePolicy">Poliza de seguro</label>
                            <input name="insurancePolicy" type="text" className='form-control'
                                value={formik.values.insurancePolicy ?? ""}
                                onChange={formik.handleChange} />
                            {formik.errors.insurancePolicy ? <div className="text-danger text-sm">{formik.errors.insurancePolicy}</div> : null}

                        </div>

                        <div className="col-md-6 col-xs-6">
                            <label className="control-label" for="insuranceMembership">No. Socio</label>
                            <input name="insuranceMembership" type="text" className='form-control'
                                value={formik.values.insuranceMembership ?? ""}
                                onChange={formik.handleChange} />
                            {formik.errors.insuranceMembership ? <div className="text-danger text-sm">{formik.errors.insuranceMembership}</div> : null}

                        </div>

                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className='col-6'>
                            {formik.errors ?
                                <ul>
                                    {Object.values(formik.errors).map((item, index) => {
                                        return (<li className='text-danger' key={index}>{item}</li>);
                                    })}
                                </ul> : null}
                        </div>
                        <div className="col-6 text-right">
                            {props.onCamperScreen?
                            <>
                            <button className="btn btn-primary" type="submit" >Guardar</button>
                            </>:
                            <>
                            <button className="btn btn-default" type="button" onClick={() => props.setActiveStep(props.step - 1)}>Retroceder</button>
                            <button className="btn btn-primary" type="submit" >Continuar</button>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </>)
}

export default Health;