import React, { useEffect } from 'react';
import { useState } from '@hookstate/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Carousel } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import logo from "../../assets/img/logo_camp_black.png";
import ClearUser from '../../common/ClearUser';
import api from '../../common/api';
import { parseJwt } from '../../common/helpers';
import img01 from "../../assets/img/RBS_0866.JPG";
import img02 from "../../assets/img/RBS_3131.JPG";
import img03 from "../../assets/img/RBS_6506.JPG";

const Login = props => {
  const state = useState({
    Email: "", Password: "",
    UI: {
      IsLoading: false,
      validated: false,
      Error: null
    }
  });

  useEffect(() => {

    ClearUser();
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log("form validity", form.checkValidity());
    if (form.checkValidity() === false) {

      event.stopPropagation();

    } else {

      console.log("Continue to next process", state.value);



      try {
        state.UI.IsLoading.set(true);
        let query = {
          username: state.Email.value,
          password: state.Password.value
        };

        state.UI.validated.set(true);
        let request = await api.postAction("account/RequestToken", query);
        state.UI.IsLoading.set(false);
        let response = request.data;
        console.log("token", response.token);
        console.log("Parsed token", parseJwt(response.token));
        localStorage.setItem("LogedUser", response.token);
        props.history.push(Routes.Dashboard.path);
      } catch (err) {
        console.log("Error", err);
        debugger;
        state.UI.Error.set(err);
        state.UI.IsLoading.set(false);
      }
    }

  };

  return (
    <main>
      <section className="d-flex align-items-center p-0 m-0">


        <Container className='full p-0'>
          <Row>
            <div className="col-md-4 col-sm-12 d-flex align-items-center justify-content-center p-0 m-0 bg-white ">

              <div className=" p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <img src={logo} className="img-fluid mb-4" style={{ maxHeight: "75px" }} />
                  <h4 className="mb-0">Ingresar</h4>
                  {state.UI.Error.value !== null ? <small className=" text-danger">Invalid username or password</small> : ""}
                </div>
                <Form className="mt-4"

                  noValidate validated={state.UI.validated.value} onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Correo</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com"
                        value={state.Email.value}
                        onChange={(e) => { state.Email.set(e.target.value) }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Favor ingresar un correo valido
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password"
                          value={state.Password.value}
                          onChange={(e) => { state.Password.set(e.target.value) }} />
                        <Form.Control.Feedback type="invalid">
                          Password is required.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4 text-right">

                      <Card.Link className="small text-end" as={Link} to={Routes.ForgotPassword.path}>Olvidó su contraseña?</Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100"
                    disabled={state.UI.IsLoading.value}
                  >
                    {state.UI.IsLoading.value ?

                      <span className="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true"></span> : null}
                    Ingresar
                  </Button>
                </Form>


                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Aún no está registrado?
                    <Card.Link as={Link} to={Routes.Register.path} className="fw-bold">
                      {` Crear Cuenta `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-8 d-none d-md-block SliderContainer p-0">
              <Carousel>
                <Carousel.Item
                  style={{ backgroundImage: `url('${img01}')` }}>

                  <Carousel.Caption>
                    <h3 className='text-light display-3'>Quiero</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item
                  style={{ backgroundImage: `url('${img02}')` }}>

                  <Carousel.Caption>
                    <h3 className='text-light display-3'>Puedo</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item
                  style={{ backgroundImage: `url('${img03}')` }}>
                  <Carousel.Caption>
                    <h3 className='text-light display-3'>Soy Capaz</h3>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>


            </div>

          </Row>

        </Container>
      </section>
    </main>
  );
}

export default Login;