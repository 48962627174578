
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import api from "../../common/api";


export default () => {

  const [Email, setEmail] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log("form validity", form.checkValidity());
    if (form.checkValidity() === false) {

      event.stopPropagation();

    } else {

      
      try {
        
     
        
        let request = await api.getAction("account/forgotpassword", "email="+Email);
        alert("Favor revisar su correo")
      } catch (err) {
        console.error("Error", err);
        debugger;
        
      }
    }

  };


  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.SignIn.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Volver al ingreso
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Recuperar su contraseña</h3>
                
                <Form 

noValidate  onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Digite su Correo</Form.Label>
                    <InputGroup id="email">
                      <input type="email" required autoFocus placeholder="john@company.com" className="form-control"
                      value={Email}
                      onChange={e=>{
                        setEmail(e.target.value);
                      }}
                      />
                    </InputGroup>
                  </div>
                  <Button variant="primary" type="submit" className="w-100">
                    Recuperar
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
