
export const Routes = {
    // pages
    Home : {path:"/#"},
    Dashboard: { path: "/" },
    Settings: {path:"/settings"},
    Projects: {path:"/projects"},
    ProjectDetails: {path:"/Projects/detail/:id", clean:"/Projects/Detail/"},
    
    NotFound: { path: "/examples/NotFound" },
    ServerError: { path: "/examples/ServerError" },
    
    
    Profile: {path:"/profile"},
    SignIn: {path:"/signin"},
    Register: {path:"/Register"},
    ForgotPassword: {path: "/ForgotPassword"},
    ResetPassword: { path: "/ResetPassword" },

    Campers: { path: "/campers" },
    CamperDetails: { path: "/Campers/details/:id", clean: "/Campers/Details/" },
    CamperDetailsExternal: {path: "/Campers/details/external/:id", clean: "/campers/details/external/"},
    MasiveEmail: { path: "/MasiveEmail" },
    Transportation: { path: "/Transportation" },
    Rooms: { path: "/Rooms" },
    Users: { path: "/users" },
    UserDetails: { path: "/users/detail/:id", clean: "/users/detail/" },


    ReportsPreferCampingWith: {path:"/reports/preferCampingWith", clean:"/reports/PreferCampingWith"},
    ReportsAllergies: {path:"/reports/Allergies", clean:"/reports/Allergies"},
    ReportsContactInfo: {path:"/reports/contactInfo", clean:"/reports/contactInfo"},
    Inscription: {path:"/inscription"}
};