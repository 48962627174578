import React, { useEffect, useState } from 'react';

import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';

import api from '../../common/api';

import 'semantic-ui-css/semantic.min.css'
import { Icon, Step } from 'semantic-ui-react'
import Subscriptions from './Subscriptions';
import moment from 'moment';
import Camper from './Camper';
import Final from './Final';
import Health from './Health';
import logo from "../../assets/img/logo_camp_black.png";
import { parsedUser } from '../../common/GetCurrentUser';
import Parents from '../Users/Parents';
import Kids from '../Users/Kids';
import * as yup from 'yup';
import ThankYou from './ThankYou';

//Validation
const validationSchema = yup.object({
    subscriptionId: yup.string().required(),
    //identifier: yup.string().required(),
    birthdate: yup.string().required(),
    city: yup.string().required(),
    school: yup.string().required(),
    grade: yup.string().required(),
    shirtSizeId: yup.string().required(),
    camperId: yup.number().min(1),
    amountOfParents: yup.number().min(1),

});

const Inscription = props => {
    const [currentUser, setCurrentUser] = useState(null);
    const [selectedKid, setSelectedKid] = useState(null);
    const [application, setApplication] = useState(null);
    const saveApplication = obj => {
        setApplication(obj);
    }
    const [state, setState] = useState({
        Year: {},
        UI: {
            IsLoading: false,
            validated: false,
            Error: null,
            Step: 0,
        }
    });

    const [model, setModel] = useState({
        subscriptionId: null,
        camperId: null,
        amountOfParents: 0,
        school: "",
        grade: "",
        city: "",
        shirtSizeId: null,
        birthDate: null,


    });

    useEffect(() => {
        let theUser = parsedUser();
        setCurrentUser(theUser);
        Request();
    }, [])

    const Request = async () => {


        let request = await api.getAction("year/current", "");
        console.log("request response", request);
        if (request.data.status === "ok") {

            setState({
                ...state,
                Year: request.data.response,
                UI: {
                    ...state.UI,
                    Page: request.data.page,
                    QuantityOfPages: request.data.quantityOfPages
                }

            });

        }
    }

   
    const [step, setStep] = useState(0);
    const setActiveStep = obj => {

        setStep(obj);

        if(obj ===5){
            //thank you
            sendMails();
        }
    }

    const sendMails = async () => {
        
        let query = "camperid=" + application.id
        let request = await api.getAction("campers/finish", query);
        if (request.data.status === "ok") {
            
        }
    }

    const selectCamper = obj => {
        setSelectedKid(obj);
        setModel({
            ...model,
            camperId: obj !== null ? obj.id : null
        })
    }
    const getCountOfParents = objs => {
        setModel({
            ...model,
            amountOfParents: objs.length
        })
    }
    const selectSubscription = selected => {
        setStep(1);
        setModel({
            ...model,
            subscriptionId: selected.id
        });
    }



    return (
        <main className='generalContainerEmpty'>
            <img id="hero-mountains" className="mountains-second skrollable skrollable-between" data-75p-top="bottom:0px;" data-80p="bottom:-90px;" src="https://campamentobaiguate.com/wp-content/themes/baiguate/assets/img/mountainsP-2.svg" type="image/svg+xml" style={{ bottom: "-18.1973px", position: "fixed" }} />
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <Row>
                        <Col className='text-center'>
                            <img src={logo} className="img-fluid mb-4" style={{ maxHeight: "75px" }} />
                        </Col>
                    </Row>
                    <h2 className="text-center mb-4 text-dark">
                    {moment(state.Year.year).add(-1, "years").format("YYYY")}-{moment(state.Year.year).format("YYYY")} 
                    </h2>
                    <Row className="justify-content-center form-bg-image " >
                        <Col xs={12} className="d-flex align-items-center justify-content-center d-none d-md-block">
                            <Step.Group widths={6}>
                                <Step
                                    //onClick={() => setActiveStep(0)} 
                                    active={step === 0}
                                >

                                    <Step.Title>Programa</Step.Title>
                                </Step>

                                <Step
                                    //onClick={() => setActiveStep(1)} 
                                    active={step === 1}
                                >

                                    <Step.Title>Acampante</Step.Title>

                                </Step>
                                <Step
                                    //onClick={() => setActiveStep(2)} 
                                    active={step === 2}
                                >


                                    <Step.Title>Padres/Tutores</Step.Title>

                                </Step>
                                <Step
                                    //onClick={() => setActiveStep(3)} 
                                    active={step === 3}
                                >

                                    <Step.Title>Salud</Step.Title>
                                </Step>
                                <Step
                                    //</Step.Group>onClick={() => setActiveStep(4)} 
                                    active={step === 4}
                                >

                                    <Step.Title>Final</Step.Title>
                                </Step>
                            </Step.Group>



                        </Col>
                        <Col xs={12} className="my-4">
                            {state.Year.subscriptions && step === 0 ? <Subscriptions onSelect={selectSubscription} model={state.Year.subscriptions} /> : null}
                            {step === 1 ?
                                selectedKid === null ?
                                    <Kids User={currentUser.ID} forInscription CurrentCampYear={state.Year} selectTheKid={selectCamper} /> :
                                    <Camper step={step}
                                        setActiveStep={setActiveStep}
                                        model={selectedKid}
                                        selectedSubscriptionId={model.subscriptionId}
                                        saveApplication={saveApplication}
                                        Year={state.Year}
                                        selectTheKid={selectCamper} /> : null}
                            {step === 2 ?
                                <Parents User={currentUser.ID} forInscription step={step}
                                    setActiveStep={setActiveStep} getCountOfParents={getCountOfParents} />
                                : null}
                            {step === 3 ? <Health step={step} setActiveStep={setActiveStep}
                                saveApplication={saveApplication}
                                model={selectedKid}
                                application={application} /> : null}
                            {step === 4 ? <Final step={step} setActiveStep={setActiveStep} model={selectedKid}

                            /> : null}

                            {step === 5 ? <ThankYou step={step} setActiveStep={setActiveStep} model={selectedKid}

                            /> : null}

                        </Col>
                        <Col xs={12} >
                            <a href="/" className='btn btn-secondary'>Volver al inicio</a>
                        </Col>
                    </Row>
                </Container>
            </section>


        </main>
    );
}

export default Inscription;