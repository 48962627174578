import React, { useEffect } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';
import { useState } from '@hookstate/core';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';


import api from '../../common/api';
//Validation
const validationSchema = yup.object({
    Password: yup.string().required("required"),

});
//Component
const ChangePassword = props => {
    const state = useState({
        UI: {
            Validated: false
        },
    })
    const handleSubmit = async (obj, event) => {

        console.log("values to submit", obj);
        state.UI.isLoading.set(!state.UI.isLoading.value);
        let query = "UserId="+ obj.UserId+"&Password="+ obj.Password;
        let request = await api.postAction("Users/ChangePassword?"+query,null);
        state.UI.isLoading.set(!state.UI.isLoading.value);
        console.log("response from adding", request);
        if (request.status === 200) {
            props.toggle(null, true);
        }
    };
    const formik = useFormik({
        initialValues: JSON.parse(JSON.stringify(props.model)),
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Cambiar Contraseña</Modal.Title>
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>

                    <Row>
                        <Col xs={12}>
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control type="text"
                                name="Password"
                                value={formik.values.Password} onChange={formik.handleChange} />
                            {formik.errors.Password ? <div className="invalid text-sm">{formik.errors.Password}</div> : null}
                        </Col>
                     
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading.value}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )
}

export default ChangePassword;