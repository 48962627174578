import React, { useEffect, useState } from 'react';

// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import api from '../../common/api';
import AddEditParent from './AddEditExtraExpense';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { v4 as uuidv4 } from 'uuid';
import AddEditExtraExpense from './AddEditExtraExpense';
import { FormatMoney } from '../../common/helpers';
const ExtraExpensesForCamper = (props) => {

    const [state, setState] = useState([]);
    const [showAddEdit, setShowAddEdit] = useState(false);
    

    const toggleAddEdit = async ( reload) => {
    
        setShowAddEdit(!showAddEdit);

        if (reload) {
            await bindDataRemote();
        }
    }
    useEffect(() => {

        bindDataRemote();

    }, [props.Camper, props.User])

    const deleteConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Está seguro de eliminar este gasto extra?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await api.getAction("extraExpenses/DeleteExpenseForCamper", "id=" + obj.id);
                        await bindDataRemote();
                    }
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }

    const deletePaymentConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Esta seguro de eliminar este pago?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await api.getAction("extraExpenses/DeleteExpensePaymentForCamper", "id=" + obj.id);
                        await bindDataRemote();
                    }
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const addPayment = obj => {
        setSelectedExpense(obj);
        setShowAddPayment(!showAddPayment);

    }
    const bindDataRemote = async () => {
        try {
            let query = "";
            if (props.Camper) {
                query += "id=" + props.Camper;
            }


            const request = await api.getAction(
                "ExtraExpenses/ListForUser", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setState(response.response);

            }
        } catch (ex) {
            console.error(ex);
        }
    }
    return (
        <>
            {showAddEdit ?
                <AddEditExtraExpense model={props.Camper} toggle={toggleAddEdit} show={showAddEdit} /> :
                null}

            <Card border="light" className=" p-0 mb-4">

                <Card.Body className="table-responsive">

                    <Card.Title>Gastos Extras
                        <button className='btn btn-primary btn-sm mx-2 d-none' type="button"
                            onClick={() => {
                                toggleAddEdit();
                            }}>
                            <i className="fa fa-plus" /> Agregar Gasto
                        </button>
                    </Card.Title>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Valor</th>
                                <th>Pendiente</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.map((item, index) => {
                                let pending = item.extraExpense.cost * 1;
                                if (item.kidCampYearExtraExpensePayments) {
                                    item.kidCampYearExtraExpensePayments.forEach(element => {
                                        pending = pending - element.amount * 1;
                                    });
                                }

                                return (<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <b>{item.extraExpense.name}</b>
                                        {/* LIST PAYMENTS MADE */}
                                        <ul>
                                            {item.kidCampYearExtraExpensePayments && item.kidCampYearExtraExpensePayments.map((payment, indexpayment) => {
                                                return (
                                                    <li key={indexpayment}>
                                                        {moment(payment.date).format("MMM DD, YYYY")} {FormatMoney(payment.amount)}
                                                        <button className='ms-2 btn btn-sm text-danger d-none' title='Eliminar pago' type="button " onClick={() => {
                                                            deletePaymentConfirm(payment)
                                                        }}>

                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                    </li>
                                                )
                                            }
                                            )}

                                        </ul>
                                    </td>
                                    <td>{FormatMoney(item.extraExpense.cost)}</td>
                                    <td>
                                        {FormatMoney(pending)}
                                    </td>

                                    <td className='text-right'>
                                        <div className='btn-group'>
                                            <button className='btn btn-sm btn-primary d-none' type="button " onClick={() => {
                                                addPayment(item)
                                            }}>
                                                Agregar Pago
                                            </button>

                                            <button className='btn btn-sm btn-danger d-none' type="button " onClick={() => {
                                                deleteConfirm(item)
                                            }}>
                                                <i className='fa fa-trash'></i>
                                            </button>

                                        </div>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </Card.Body>

            </Card>
        </>
    );
};

export default ExtraExpensesForCamper;