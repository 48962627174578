import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { Form } from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import ShirtSize from "./shirtSize";
import api from "../../common/api";

//Validation
const validationSchema = yup.object({
  grade: yup.string().nullable().label("Curso").required(),
  schoolName: yup.string().nullable().label("Colegio").required(),
  shirtSizeId: yup.string().nullable().label("Tamaño Camiseta").required(),
});

const Camper = (props) => {
  const [showSizeChart, setShowSizeChart] = useState(false);
  const toggleSizeChart = () => {
    setShowSizeChart(!showSizeChart);
  };

  const [model, setModel] = useState({
    subscriptionId: props.selectedSubscriptionId,
    kidId: props.model.id,
    shirtSizeId: null,
    schoolName: null,
    grade: null,
    partnersNames: props.model.partnersNames,
  });

  const requestProfile = async () => {
    let query = "kidid=" + props.model.id;
    let request = await api.getAction("campers/exist", query);
    if (request.data.status === "ok") {
      let result = request.data.response;
      let obj = {
        subscriptionId: props.selectedSubscriptionId,
        kidId: props.model.id,
        shirtSizeId: result?.shirtSizeId,
        schoolName: result?.school.name,
        grade: result?.grade,
        partnersNames: props.model.partnersNames,
      };
      setModel(obj);
      formik.setValues(obj);
    }
  };
  const handleSubmit = async (obj, event) => {
    //Now we are sending to the server
    let request = await api.postAction("campers/add", obj);

    if (request.data.status === "ok") {
      props.saveApplication(request.data.response);
      props.setActiveStep(props.step + 1);
    }
  };
  const formik = useFormik({
    initialValues: model,
    validationSchema,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    requestProfile();
  }, []);
  return (
    <>
      {showSizeChart ? (
        <ShirtSize toggle={toggleSizeChart} show={showSizeChart} />
      ) : null}
      <Form onSubmit={formik.handleSubmit}>
        <div className="card shadow-sm mt-2">
          <div className="card-body">
            <div className="row  ">
              <div className="col-md-6 col-xs-6">
                <label>Primer Nombre</label>
                <input
                  className="form-control text-box single-line"
                  name="firstName"
                  readOnly
                  value={props.model.firstName ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.firstName ? (
                  <div className="text-danger text-sm">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-6">
                <label>Segundo Nombre</label>
                <input
                  className="form-control text-box single-line"
                  name="middleName"
                  readOnly
                  value={props.model.middleName ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.middleName ? (
                  <div className="text-danger text-sm">
                    {formik.errors.middleName}
                  </div>
                ) : null}
              </div>

              <div className="col-md-6 col-xs-6">
                <label htmlFor="LastName">Apellido</label>
                <input
                  className="form-control text-box single-line"
                  name="lastName"
                  readOnly
                  value={props.model.lastName ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.lastName ? (
                  <div className="text-danger text-sm">
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-6">
                <label htmlFor="SecondLastName">Segundo Apellido</label>
                <input
                  className="form-control text-box single-line"
                  name="secondLastName"
                  readOnly
                  value={props.model.secondLastName ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.secondLastName ? (
                  <div className="text-danger text-sm">
                    {formik.errors.secondLastName}
                  </div>
                ) : null}
              </div>

              <div className="col-md-6 col-xs-6">
                <label htmlFor="GenderID">Género</label>
                <select
                  className="form-control"
                  name="genderId"
                  readOnly
                  value={props.model.genderId ?? ""}
                  onChange={formik.handleChange}
                >
                  <option value="">-</option>
                  <option value="1">Masculino</option>
                  <option value="2">Femenino</option>
                </select>
                {formik.errors.genderId ? (
                  <div className="text-danger text-sm">
                    {formik.errors.genderId}
                  </div>
                ) : null}
              </div>

              <div className="col-md-6 col-xs-6">
                <label>
                  Fecha de Nacimiento <span className="text-red">*</span>
                </label>
                <input
                  type="date"
                  name="birthdate"
                  className="form-control "
                  readOnly
                  value={
                    props.model.birthdate
                      ? moment(props.model.birthdate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.birthdate ? (
                  <div className="text-danger text-sm">
                    {formik.errors.birthdate}
                  </div>
                ) : null}
              </div>

              <div className="col-md-6 col-xs-6">
                <label htmlFor="Phone">Celular</label>
                <input
                  className="form-control text-box single-line"
                  name="phone"
                  readOnly
                  value={props.model.phone ? props.model.phone : ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.phone ? (
                  <div className="text-danger text-sm">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-6">
                <label htmlFor="Email">Email</label>

                <input
                  className="form-control text-box single-line"
                  name="email"
                  readOnly
                  value={props.model.email ? props.model.email : ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.email ? (
                  <div className="text-danger text-sm">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>

              <div className="col-md-6 col-xs-6">
                <label>
                  Tamaño de T-Shirt <span className="text-red">*</span>
                </label>

                <select
                  className="form-control"
                  name="shirtSizeId"
                  value={
                    formik.values.shirtSizeId ? formik.values.shirtSizeId : ""
                  }
                  onChange={formik.handleChange}
                >
                  <option value="">-</option>
                  <option value="5">06-08 (Small)</option>
                  <option value="6">10-12 (Medium)</option>
                  <option value="7">14-16 (Large)</option>
                  <option value="10">Large (Adultos)</option>
                  <option value="9">Medium (Adultos)</option>
                  <option value="8">Small (Adultos)</option>
                  <option value="11">XL (Adultos)</option>
                </select>
                {formik.errors.shirtSizeId ? (
                  <div className="text-danger text-sm">
                    {formik.errors.shirtSizeId}
                  </div>
                ) : null}
                <small>
                  No somos responsables de la talla incorrecta. <br />
                  <button
                    type="button"
                    className="btn btn-xs btn-success"
                    onClick={() => {
                      toggleSizeChart();
                    }}
                  >
                    Size Chart
                  </button>
                </small>
              </div>
              <div className="col-md-6 col-xs-6">
                <label htmlFor="KnowToSwim">Sabe nadar</label>
                <select
                  className="form-control"
                  readOnly
                  name="knowToSwim"
                  value={props.model.knowToSwim ?? ""}
                  onChange={formik.handleChange}
                >
                  <option value=""></option>
                  <option value="true">Si</option>

                  <option value="false">No</option>
                </select>

                {formik.errors.knowToSwim ? (
                  <div className="text-danger text-sm">
                    {formik.errors.knowToSwim}
                  </div>
                ) : null}
              </div>

              <div className="col-xs-12">
                <label htmlFor="PartnersNames">
                  Nombre de los compañeros con quién quiere compartir habitación
                </label>
                <textarea
                  className="form-control"
                  cols="20"
                  name="partnersNames"
                  value={
                    formik.values.partnersNames
                      ? formik.values.partnersNames
                      : ""
                  }
                  onChange={formik.handleChange}
                  rows="2"
                ></textarea>
                {formik.errors.partnersNames ? (
                  <div className="text-danger text-sm">
                    {formik.errors.partnersNames}
                  </div>
                ) : null}
              </div>

              <p>&nbsp;</p>
              <h3 className="text-uppercase margin">
                <small>Dirección</small>
              </h3>
              <hr />

              <div className="col-4">
                <label>Dirección</label>

                <input
                  className="form-control text-box single-line"
                  name="address"
                  readOnly
                  value={props.model.address ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.address ? (
                  <div className="text-danger text-sm">
                    {formik.errors.address}
                  </div>
                ) : null}
              </div>
              <div className="col-4">
                <label>Ciudad</label>

                <input
                  className="form-control text-box single-line"
                  name="city"
                  readOnly
                  value={props.model.city ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.city ? (
                  <div className="text-danger text-sm">
                    {formik.errors.city}
                  </div>
                ) : null}
              </div>
              <div className="col-4">
                <label>Provincia</label>

                <input
                  className="form-control text-box single-line"
                  name="provincia"
                  readOnly
                  value={props.model.provincia ?? ""}
                  onChange={formik.handleChange}
                />

                {formik.errors.provincia ? (
                  <div className="text-danger text-sm">
                    {formik.errors.provincia}
                  </div>
                ) : null}
              </div>

              <p>&nbsp;</p>
              <h3 className="text-uppercase margin">
                <small>Educación</small>
              </h3>
              <hr />

              <div className="col-md-6 col-xs-6">
                <label>Colegio</label>

                <input
                  className="form-control text-box single-line"
                  name="schoolName"
                  value={
                    formik.values.schoolName ? formik.values.schoolName : ""
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.schoolName ? (
                  <div className="text-danger text-sm">
                    {formik.errors.schoolName}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-6">
                <label>
                  Curso (
                  {moment(props.Year.year).add(-1, "years").format("YYYY")} /{" "}
                  {moment(props.Year.year).format("YYYY")} )
                </label>
                <select
                  className="form-control"
                  name="grade"
                  value={formik.values.grade ? formik.values.grade : ""}
                  onChange={formik.handleChange}
                >
                  <option value="">-</option>
                  <optgroup label="Nivel Primario">
                    <option value="Primaria-Primer Grado">Primer Grado</option>
                    <option value="Primaria-Segundo Grado">
                      Segundo Grado
                    </option>
                    <option value="Primaria-Tercer Grado">Tercer Grado</option>
                    <option value="Primaria-Cuarto Grado">Cuarto Grado</option>
                    <option value="Primaria-Quinto Grado">Quinto Grado</option>
                    <option value="Primaria-Sexto Grado">Sexto Grado</option>
                  </optgroup>
                  <optgroup label="Nivel Secundaria">
                    <option value="Secundaria-Primer Grado">
                      Primer Grado
                    </option>
                    <option value="Secundaria-Segundo Grado">
                      Segundo Grado
                    </option>
                    <option value="Secundaria-Tercer Grado">
                      Tercer Grado
                    </option>
                    <option value="Secundaria-Cuarto Grado">
                      Cuarto Grado
                    </option>
                    <option value="Secundaria-Quinto Grado">
                      Quinto Grado
                    </option>
                    <option value="Secundaria-Sexto Grado">Sexto Grado</option>
                  </optgroup>
                </select>

                {formik.errors.grade ? (
                  <div className="text-danger text-sm">
                    {formik.errors.grade}
                  </div>
                ) : null}
                <br />
                <small>NOTA: CURSO ACTUAL</small>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-6">
                {formik.errors ? (
                  <ul>
                    {Object.values(formik.errors).map((item, index) => {
                      return (
                        <li className="text-danger" key={index}>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
              <div className="col-6 text-right">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={() => {
                    //  props.setActiveStep(props.step - 1)
                    props.selectTheKid(null);
                  }}
                >
                  Retroceder
                </button>
                <button className="btn btn-primary" type="submit">
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Camper;
