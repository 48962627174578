import { useState } from "@hookstate/core"
import React, { useEffect } from "react"
import { FormatMoney } from "../../common/helpers"
import moment from 'moment';
const Subscriptions = props => {
    console.log("We get this in subscriptions", props)


    useEffect(() => {

    }, [])

    return (
        <>
            <h4 className="text-light">Seleccione el programa deseado</h4>
            {props.model && props.model.map((item, index) => {
                return (<div className="mt-2 card shadow-sm clickable" key={index} onClick={() => props.onSelect(item)}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                {item.name}
                                <br />
                                <small className="text-muted">{moment(item.date).format("MMM DD, YYYY")}</small>
                            </div>

                            <div className="col-auto ml-auto text-right">
                                USD$ {FormatMoney(item.cost)}
                            </div>
                        </div>

                    </div>
                </div>);
            })}

        </>)
}

export default Subscriptions;