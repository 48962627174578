
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Routes } from "../../routes";
import api from "../../common/api";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default () => {
const theQuery= useQuery();
  const [password, setPassword] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log("form validity", form.checkValidity());
    if (form.checkValidity() === false) {

      event.stopPropagation();

    } else {

      
      try {
        
        let query = "Token="+theQuery.get("token")+"&password="+password
        let request = await api.postAction("account/resetPassword", query);
        alert("Contraseña re-establecida, favor ingresar con su nuevo password")
        window.location = "/";
      } catch (err) {
        console.error("Error", err);
        debugger;
        
      }
    }

  };


  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.SignIn.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Volver al ingreso
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Recuperar su contraseña</h3>
                
                <Form 

noValidate  onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Form.Label htmlFor="password">Digite su nueva contraseña</Form.Label>
                    <InputGroup id="password">
                      <input type="password" required autoFocus  className="form-control"
                      value={password}
                      onChange={e=>{
                        setPassword(e.target.value);
                      }}
                      />
                    </InputGroup>
                  </div>
                  <Button variant="primary" type="submit" className="w-100">
                    Establecer
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
