import React, { useEffect, useState } from 'react';

// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import api from '../../common/api';
import AddEditParent from './AddEditExtraExpense';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { v4 as uuidv4 } from 'uuid';
import AddEditExtraExpense from './AddEditExtraExpense';
import { FormatMoney } from '../../common/helpers';
const ProgramSubscribed = (props) => {

    const [state, setState] = useState([]);
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selectedParent, setSelectedParent] = useState(null);
    const [pendingValue, setPendingValue]= useState(0);
    const toggleAddEdit = async (parentSelected, reload) => {
        if (!parentSelected) {
            parentSelected = {
                id: 0,
                name: "",
                middleName: "",
                lastName: "",
                secondLastName: "",
                email: "",
                phone: "",
                phone2: "",
                phone3: "",
                identifier: "",
                address: "",
                userId: props.User

            }
        }

        setSelectedParent(parentSelected);
        setShowAddEdit(!showAddEdit);

        if (reload) {
            await bindDataRemote();
        }
    }
    useEffect(() => {

        bindDataRemote();

    }, [props.Camper, props.User])

    const deleteConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Está seguro de eliminar este gasto?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await api.getAction("Campers/DeleteSubscriptionPayment", "id=" + obj.id);
                        await bindDataRemote();
                    }
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }

    const bindDataRemote = async () => {
        try {
            let query = "";
            if (props.Camper) {
                query += "camperId=" + props.Camper;
            }


            const request = await api.getAction(
                "Campers/Subscription", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setState(response.response);

            }
        } catch (ex) {
            console.error(ex);
        }
    }
    if (!state.subscription) {
        return (<></>)
    }

    const pendingPayment = () => {
        let pending =state.subscription.cost;
        if (state.subscriptionPayments) {
            state.subscriptionPayments.forEach(element => {
                if(element.discount){
                    pending = pending- element.discount;
                }else{
                    pending =pending- element.amount
                }
            })
        }


        return FormatMoney(pending);
    }

    return (
        <>
            {showAddEdit ?
                <AddEditExtraExpense model={selectedParent} toggle={toggleAddEdit} show={showAddEdit} /> :
                null}

            <Card border="light" className=" p-0 mb-4">

                <Card.Body className="table-responsive">

                    <Card.Title>Programa - <small>{state.subscription.name} ({moment(state.subscription.date).format("MMM DD, YYYY")})</small>
                        <button className='btn btn-primary btn-sm mx-2 d-none' type="button"
                            onClick={() => {
                                toggleAddEdit();
                            }}>
                            <i className="fa fa-plus" /> Agregar pago
                        </button>
                        <button className='btn btn-secondary btn-sm mx-2 d-none' type="button"
                            onClick={() => {
                                toggleAddEdit();
                            }}>
                            <i className="fa fa-plus" /> Agregar descuento
                        </button>
                    </Card.Title>
                    <p>
                        <b>Costo: </b> {FormatMoney(state.subscription.cost)}
                        <br/>
                        <b>Pendiente: </b> {pendingPayment()}
                    </p>
                    <h4>Pagos</h4>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Fecha</th>
                                <th>Valor</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.subscriptionPayments && state.subscriptionPayments.map((item, index) => {

                                return (<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{moment(item.date).fromNow()}

                                    </td>
                                    <td>{FormatMoney(item.amount)}</td>
                                 

                                    <td className='text-right'>
                                        <div className='btn-group'>

                                            <button className='btn btn-sm btn-danger d-none' type="button " onClick={() => {
                                                deleteConfirm(item)
                                            }}>
                                                <i className='fa fa-trash'></i>
                                            </button>

                                        </div>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </Card.Body>

            </Card>
        </>
    );
};

export default ProgramSubscribed;