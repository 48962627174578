import React, { useEffect, useState } from 'react';
import { Routes } from '../../routes';
// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import History from '../../common/History';
import QRCode from 'react-qr-code';
import Settings from '../../common/Settings';
import AddEditKid from '../Users/AddEditKid';

import formularioMedico from '../../assets/evaluacionMedica2023.docx';
import descargo from '../../assets/DESCARGO2022.pdf';
import api from '../../common/api';

const CamperProfileCard = (props) => {
    const [localCamper, setLocalCamper] = useState(props.Camper);
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selectedKid, setSelectedKid] = useState(null);
    const toggleAddEdit = async (kidSelected, reload) => {
        setSelectedKid(kidSelected);
        setShowAddEdit(!showAddEdit);

        if (reload) {
            // await requestRecord();
        }
    }
    const request = async () => {

        let request = await api.getAction("Campers/camperyeardetails", "id=" + localCamper.id);
        console.log("request response", request);
        if (request.data.status === "ok") {
            setLocalCamper(request.data.response);
        }
    }
    const AddAttachmentsFormularioMedico = (e) => {
        console.log("FILES", e.target.files);
        var theFiles = Array.from(e.target.files);

        theFiles.forEach(async item => {

            // setIsLoading(!isLoading);

            let bodyData = new FormData();
            bodyData.append("path", item);

            await api.postAction(`campers/UploadFileFormularioMedico?id=${props.Camper.id}`, bodyData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            await request();
            //    setIsLoading(!isLoading);
            //  await requestFolderRemote();

        });
    };

    const AddAttachmentsDescargo = (e) => {
        console.log("FILES", e.target.files);
        var theFiles = Array.from(e.target.files);

        theFiles.forEach(async item => {

            // setIsLoading(!isLoading);

            let bodyData = new FormData();
            bodyData.append("path", item);

            await api.postAction(`campers/UploadFileDescargo?id=${props.Camper.id}`, bodyData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            await request();
            //    setIsLoading(!isLoading);
            //  await requestFolderRemote();

        });
    };

    console.log("Value received", props, localCamper);

    let years = moment().diff(localCamper.kid.birthdate.substring(0, 10), "years", false);

    useEffect(() => {
        request();
    }, [])

    return (<>
        {showAddEdit ?
            <AddEditKid model={selectedKid} toggle={toggleAddEdit} show={showAddEdit} /> : null
        }
        <Card border="light" className="text-center p-0 mb-4">
            {/* <div style={{ backgroundImage: `url(${ProfileCover})` }} className="profile-cover rounded-top" /> */}
            <Card.Body className="pb-5">
                <QRCode value={Settings.APP_PLUBLIC_PATH + "external/" + localCamper.id}></QRCode>
                <Card.Title className="mt-2 text-uppercase">{localCamper.kid.firstName} {localCamper.kid.middleName} <br />{localCamper.kid.lastName} {localCamper.kid.secondLastName}  </Card.Title>
                <Card.Subtitle className="fw-normal">{localCamper.kid.email}</Card.Subtitle>
                <Card.Text className="text-gray mb-4"><b>Fecha de Nacimiento: </b>{moment(localCamper.kid.birthdate).format("MMM DD, YYYY")} ({years} años)</Card.Text>




                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>Teléfono: </b>
                        <span className='ml-auto'>{localCamper.kid.phone ? localCamper.kid.phone : "-"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>Género: </b>
                        <span className='ml-auto'>{localCamper.kid.gender ? localCamper.kid.gender.name : "-"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>Id Baiguate: </b>
                        <span className='ml-auto'>{localCamper.kid.baiguateroId ? localCamper.kid.baiguateroId : "-"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>ID Maleta:</b>
                        <span className="ml-auto">{localCamper.idmaleta ?? "-"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>Programa</b>
                        <span className="ml-auto">
                            {localCamper.subscription ?
                                <>{localCamper.subscription.name} ({moment(localCamper.subscription.date).format("MMM DD, YYYY")})</> : "-"}</span>
                    </li>
                    <li className="list-group-item text-left">
                        <h5 className='text-left'>Formulario Médico
                        </h5>
                        <p><a href={formularioMedico} >Descargar formulario</a></p>
                        
                        <div className={" ml-auto"}>
                            {localCamper.medicalFormFile ?
                                <a className="btn btn-file btn-success btn-sm"  
                                target="_blank"
                                href={'https://api.campamentobaiguate.com/files/'+localCamper.medicalFormFile}
                                    >
                                    <i className="fas fa-download mx-2"></i>
                                    Descargar

                                </a> :
                                <button className="btn btn-file btn-warning btn-sm" type="button">
                                    <i className='fa fa-upload mx-2'></i>
                                    Subir Archivo Pendiente
                                    <input
                                        className=""
                                        onChange={AddAttachmentsFormularioMedico}
                                        id="inputFiles"
                                        type="file"
                                    //multiple
                                    />
                                </button>
                            }
                        </div>
                    </li>
                    <li className="list-group-item text-left ">
                        <h5 className=''>Descargo </h5>
                        <p><a href={descargo}>Descargar modelo descargo</a>
                        </p>
                        <p>
                        {localCamper.termsAndConditionsFile ?
                            <a className="btn btn-file btn-success btn-sm" t
                            target="_blank"
                            href={'https://api.campamentobaiguate.com/files/'+localCamper.termsAndConditionsFile}>
                                <i className="fas fa-download mx-2"></i>
                                Descargar
                            </a>
                            :
                            <button className="btn btn-file btn-warning btn-sm" type="button">
                                <i className='fa fa-upload mx-2'></i>
                                Subir Archivo Pendiente
                                <input
                                    className=""
                                    onChange={AddAttachmentsDescargo}
                                    id="inputFilesTermsAndConditions"
                                    type="file"
                                //multiple
                                />
                            </button>
                        }
                        </p>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>Sabe nadar:</b>
                        <span className="ml-auto">{localCamper.kid.knowToSwim ? "Si" : "No"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>Moja la cama:</b>
                        <span className="ml-auto">{localCamper.kid.bedWetting ? "Si" : "No"}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>Tamaño de T-Shirt:</b>
                        <span className="ml-auto">{localCamper.shirtSize ? localCamper.shirtSize.name : "-"}</span>
                    </li>

                </ul>
                {!props.IsReadOnly ?
                    <Button variant="primary" size="sm" className="me-2" onClick={() => {
                        toggleAddEdit(localCamper.kid);
                    }}>
                        Editar
                    </Button> : null}
            </Card.Body>
        </Card>
        {!props.IsReadOnly ?
            <Card className="shadow-sm mb-4" border="light" >
                <Card.Body>
                    <b>Usuario: </b>
                    <button className="btn btn-link"
                        onClick={() => {
                            History.push(Routes.UserDetails.clean + localCamper.kid.user.id)
                        }}>
                        {localCamper.kid.user.email}
                    </button>
                </Card.Body>
            </Card>
            : null}
    </>
    );
};

export default CamperProfileCard;