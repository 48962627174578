import {  Modal } from "@themesberg/react-bootstrap";

import React from "react";
import sizeChart from '../../assets/img/sizechart.jpeg';


const ShirtSize = props => {
  

    return (
        <>
            <Modal show={true} onHide={props.toggle} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Tamaño de camiseta</Modal.Title>
                </Modal.Header>
                    <Modal.Body>

                        <div className="modal-body table-responsive text-center">
                            <img src={sizeChart} style={{width: "80%"}} alt=""/>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th colSpan="9" className="text-center text-uppercase">
                                            Activewear Size Chart Adulto
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>

                                        </th>
                                        <th>S</th>
                                        <th>M</th>
                                        <th>L</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Ancho
                                        </td>
                                        <td>17.5</td>
                                        <td>20.5</td>
                                        <td>23</td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Largo
                                        </td>
                                        <td>28.5</td>
                                        <td>29</td>
                                        <td>29.5</td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Largo de la manga al centro d/cuello
                                        </td>
                                        <td>16</td>
                                        <td>17.5</td>
                                        <td>18.5</td>

                                    </tr>
                                </tbody>
                            </table>

                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th colSpan="6" className="text-center text-uppercase">
                                            Activewear Size Chart Niño
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>

                                        </th>

                                        <th>S</th>
                                        <th>M</th>
                                        <th>L</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Ancho
                                        </td>

                                        <td>16</td>
                                        <td>17</td>
                                        <td>17.5</td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Largo
                                        </td>

                                        <td>20</td>
                                        <td>22</td>
                                        <td>24</td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Largo de la manga al centro d/cuello
                                        </td>

                                        <td>14</td>
                                        <td>14.5</td>
                                        <td>16</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </Modal.Body>

            </Modal>
        </>
    )
}

export default ShirtSize;