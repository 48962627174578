import React from 'react';
import { useHistory } from 'react-router-dom';




const ThankYou = props => {

   const history = useHistory();


    return (<>
   
        <div className="card shadow-sm mt-2">
            <div className="card-body text-center">
               <h2 className='display-1 text-success'>
                ¡Gracias!
               
               </h2>
               <small>Solicitud recibida</small>
               <div className='row'>
                <div className='col-12 my-4'>
                    <button className='btn btn-secondary'
                    onClick={()=>{
                        history.push("/");
                    }}
                    >Volver al perfil</button>
                </div>
               </div>
            </div>
        </div>
        
    </>)
}

export default ThankYou;