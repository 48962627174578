import React, { useEffect, useState } from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import { ProfileCardWidget, ChoosePhotoWidget } from '../Widgets';
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";

import api from '../../common/api';
import CamperProfileCard from './CamperProfileCard'
import Education from './Education';
import Address from './Address';
import Parents from '../Users/Parents';
import { Routes } from '../../routes';
import ExtraExpensesForCamper from './ExtraExpensesForCamper';
import ProgramSubscribed from './ProgramSubscribed';
const CamperDetailsExternal = props => {

  const [state, setState] = useState({
    UI: { IsLoading: false },
    Camper: null

  })

  useEffect(() => {
    request();
  }, [])
  const request = async () => {

    let request = await api.getAction("Campers/camperyeardetails", "id=" + props.match.params.id);
    console.log("request response", request);
    if (request.data.status === "ok") {
      setState({
        ...state,
        Camper: request.data.response
      });
    }
  }
  if (state === null || state.Camper === null) {
    return (<></>)
  }
  return (<Row>

    <Col xs={12}>
      <Row>
        <Col xs={12}>
          <CamperProfileCard Camper={state.Camper} IsReadOnly/>

        </Col>

      </Row>
    </Col>

  </Row>);
}

export default CamperDetailsExternal;