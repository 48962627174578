
import React, { useState } from "react";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import logo from "../../assets/img/logo_camp_black.png";
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { useFormik } from "formik";
import api from "../../common/api";
import * as yup from 'yup';

//Validation
const validationSchema = yup.object({

  Email: yup.string().nullable().required("Requerido"),
  PasswordHash: yup.string().nullable().required("Requerido"),
  PasswordConfirmation: yup.string()
    .oneOf([yup.ref('PasswordHash'), null], 'Las contraseñas deben coincidir')
    .required("Requerido")
});

export default () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (obj, event) => {

    debugger;
    //Now we are sending to the server
    setIsLoading(true);
    let request = await api.postAction(
      "account/add", obj
    );
    setIsLoading(false);
    if (request.data.status === "ok") {
      alert("Registro existoso.");
      history.push(Routes.SignIn.path);
    }else{
      if(request.data.status === "error"){
        if(request.data.response === "user_exist"){
          alert("Este usuario ya existe.")
        }
      }
    }
  };
  const formik = useFormik({
    initialValues:
    {
      Id: '',
      Email: '',
      PasswordHash: '',
      PasswordConfirmation: '',
      FirstName: '',
      LastName: ''
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <main>
      <Form onSubmit={formik.handleSubmit}>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row>
              <Col className='text-center'>
                <img src={logo} className="img-fluid mb-4" style={{ maxHeight: "75px" }} />
              </Col>
            </Row>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Crear una cuenta</h3>
                  </div>
                  
                    <Form.Group id="email" className="mb-4">
                      <label >Correo</label>
                      <input className="form-control " name="Email" type="email"
                        value={formik.values.Email ? formik.values.Email : ""} onChange={formik.handleChange} />
                      {formik.errors.Email ? <div className="text-danger text-sm">{formik.errors.Email}</div> : null}

                    </Form.Group>
                    <Form.Group id="PasswordHash" className="mb-4">
                      <label >Contraseña</label>
                      <input className="form-control " name="PasswordHash" type="password"
                        value={formik.values.PasswordHash ? formik.values.PasswordHash : ""} onChange={formik.handleChange} />
                      {formik.errors.PasswordHash ? <div className="text-danger text-sm">{formik.errors.PasswordHash}</div> : null}
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-4">

                      <label >Confirmar Contraseña</label>
                      <input className="form-control " name="PasswordConfirmation" type="password"
                        value={formik.values.PasswordConfirmation ? formik.values.PasswordConfirmation : ""} onChange={formik.handleChange} />
                      {formik.errors.PasswordConfirmation ? <div className="text-danger text-sm">{formik.errors.PasswordConfirmation}</div> : null}

                    </Form.Group>
                    <FormCheck type="checkbox" className="d-flex mb-4">
                      <FormCheck.Input required id="terms" className="me-2" />
                      <FormCheck.Label htmlFor="terms">
                        Acepto los <Card.Link>terminos y condiciones</Card.Link>
                      </FormCheck.Label>
                    </FormCheck>

                    <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
                      Registrar
                    </Button>
                  


                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Ya tiene una cuenta?
                      <Card.Link as={Link} to={Routes.SignIn.path} className="fw-bold">
                        {` Ingrese aquí `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Form>
    </main>
  );
};
