import { Button, Modal, Col, Row, Form } from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import api from "../../common/api";
import * as yup from 'yup';
import { parsedUser } from "../../common/GetCurrentUser";
import * as moment from "moment-timezone";
//Validation
const validationSchema = yup.object({
    firstName: yup.string().nullable().required(),
    lastName: yup.string().nullable().required(),
    //identifier: yup.string().nullable().required(),
    birthdate: yup.string().nullable().required(),
    city: yup.string().nullable().required(),
    emergencyContactName: yup.string().nullable().required(),
    emergencyContactPhone: yup.string().nullable().required(),
    genderId: yup.string().nullable().required(),
  //  assuranceCompanyId: yup.string().nullable().required(),
   // bloodTypeId: yup.string().nullable().required()
});

const AddEditKid = props => {
    const [model, setModel] = useState(props.model);
    const [insurances, setInsurances] = useState([]);
    const [bloodTypes, setBloodTypes] = useState([]);
    const [state, setState] = useState({
        IsLoading: false
    })

    useEffect(() => {
        insurancesRequest();
        bloodTypesRequest();
        setTimeout(() => {
            let focus = document.getElementById("Name");
            if (focus !== null) {
                focus.focus();

            }
        }, 1000);

    }, []);

    const insurancesRequest = async () => {
        try {
            let query = "";

            const request = await api.getAction(
                "insurances/list", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setInsurances(response.response);
                //   state.SelectedFile.set(null);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    const bloodTypesRequest = async () => {
        try {
            let query = "";

            const request = await api.getAction(
                "bloodTypes/list", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setBloodTypes(response.response);
                //   state.SelectedFile.set(null);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    const handleSubmit = async (obj) => {

        try {
            setState({
                ...state,
                IsLoading: !state.IsLoading
            });

            const theUser = parsedUser();

            let query = {
                Id: theUser.ID,
                Data: obj
            }
            debugger;
            //We have to sent to api    
            const request = await api.postAction(
                'Users/AddEditKid', query
            );



            if (request.data.status === "ok") {
                props.toggle(request.data.response, true);
            }
        } catch (ex) {
            console.error(ex);
        }
        finally {
            setState({
                ...state,
                IsLoading: !state.IsLoading
            });
        }

    }


    const formik = useFormik({
        initialValues: model,
        validationSchema,
        onSubmit: handleSubmit,
    })

    return (
        <>
            <Modal show={true} onHide={props.toggle} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Niño</Modal.Title>
                </Modal.Header>



                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body>

                        {Object.values(formik.errors).map((item, index) => {
                            return (<div key={index}>
                                <span>{item}</span><br/>
                            </div>)
                        })}
                        <div className="row  ">


                            <div className="col-md-6 col-6">

                                <label >Primer Nombre</label>
                                <input className="form-control " name="firstName"
                                    value={formik.values.firstName ? formik.values.firstName : ""} onChange={formik.handleChange} />
                                {formik.errors.firstName ? <div className="text-danger text-sm">{formik.errors.firstName}</div> : null}
                            </div>
                            <div className="col-md-6 col-6">
                                <label >Segundo Nombre</label>
                                <input className="form-control " name="middleName"
                                    value={formik.values.middleName ? formik.values.middleName : ""} onChange={formik.handleChange} />
                                {formik.errors.middleName ? <div className="text-danger text-sm">{formik.errors.middleName}</div> : null}
                            </div>

                            <div className="col-md-6 col-6">

                                <label >Apellido</label>
                                <input className="form-control " name="lastName"
                                    value={formik.values.lastName ? formik.values.lastName : ""} onChange={formik.handleChange} />
                                {formik.errors.lastName ? <div className="text-danger text-sm">{formik.errors.lastName}</div> : null}
                            </div>
                            <div className="col-md-6 col-6">
                                <label >Segundo Apellido</label>
                                <input className="form-control " name="secondLastName"
                                    value={formik.values.secondLastName ? formik.values.secondLastName : ""} onChange={formik.handleChange} />
                                {formik.errors.secondLastName ? <div className="text-danger text-sm">{formik.errors.secondLastName}</div> : null}
                            </div>

                            <div className="col-md-6 col-6">
                                <label>Género</label>
                                <select className="form-control" name="genderId" value={formik.values.genderId} onChange={formik.handleChange}>
                                    <option value="">-</option>
                                    <option value="1">Masculino</option>
                                    <option value="2">Femenino</option>
                                </select>
                                {formik.errors.genderId ? <div className="text-danger text-sm">{formik.errors.genderId}</div> : null}
                            </div>

                            <div className="col-md-6 col-6">
                                <label >Fecha de Nacimiento</label>
                                <input className="form-control " name="birthdate" type="date"
                                    value={formik.values.birthdate ? moment(formik.values.birthdate).format("YYYY-MM-DD") : ""} onChange={formik.handleChange} />
                                {formik.errors.birthdate ? <div className="text-danger text-sm">{formik.errors.birthdate}</div> : null}

                            </div>

                            <div className="col-md-6 col-6">
                                <label >Celular</label>
                                <input className="form-control " name="phone"
                                    value={formik.values.phone ? formik.values.phone : ""} onChange={formik.handleChange} />
                                {formik.errors.phone ? <div className="text-danger text-sm">{formik.errors.phone}</div> : null}
                            </div>
                            <div className="col-md-6 col-6">
                                <label >Email</label>
                                <input className="form-control " name="email"
                                    value={formik.values.email ? formik.values.email : ""} onChange={formik.handleChange} />
                                {formik.errors.email ? <div className="text-danger text-sm">{formik.errors.email}</div> : null}
                            </div>
                            <div className="col-md-6 col-6">
                                <label >Baiguatero ID</label>
                                <input className="form-control " name="baiguateroId"
                                    disabled={formik.values.baiguateroId.length === 0}
                                    value={formik.values.baiguateroId ? formik.values.baiguateroId : ""} onChange={formik.handleChange} />
                                {formik.errors.baiguateroId ? <div className="text-danger text-sm">{formik.errors.baiguateroId}</div> : null}
                            </div>

                            <div className="col-md-6 col-6">
                                <label>Sabe nadar</label>
                                <select className="form-control" name="knowToSwim" value={formik.values.knowToSwim} onChange={formik.handleChange}>
                                    <option value="">-</option>
                                    <option value="true">Si</option>
                                    <option value="false">No</option>
                                </select>
                                {formik.errors.knowToSwim ? <div className="text-danger text-sm">{formik.errors.knowToSwim}</div> : null}

                            </div>
                            <div className="col-md-6 col-6">
                                <label>Moja la cama</label>
                                <select className="form-control" name="bedwetting" value={formik.values.bedwetting ? formik.values.bedwetting : ""} onChange={formik.handleChange}>
                                    <option value="">-</option>
                                    <option value="true">Si</option>
                                    <option value="false">No</option>
                                </select>
                                {formik.errors.bedwetting ? <div className="text-danger text-sm">{formik.errors.bedwetting}</div> : null}

                            </div>


                            <div className="col-12">
                                <label >Nombre de los compañeros con quién quiere compartir habitación</label>
                                <input className="form-control " name="partnersNames"
                                    value={formik.values.partnersNames ? formik.values.partnersNames : ""} onChange={formik.handleChange} />
                                {formik.errors.partnersNames ? <div className="text-danger text-sm">{formik.errors.partnersNames}</div> : null}
                            </div>


                            <div className="col-12">
                                <label >¿Quién puede sacar al niño?</label>
                                <input className="form-control " name="whoCanTakeTheKidOut"
                                    value={formik.values.whoCanTakeTheKidOut ? formik.values.whoCanTakeTheKidOut : ""} onChange={formik.handleChange} />
                                {formik.errors.whoCanTakeTheKidOut ? <div className="text-danger text-sm">{formik.errors.whoCanTakeTheKidOut}</div> : null}
                            </div>



                            <p>&nbsp;</p>
                            <h3 className="text-uppercase margin">
                                <small>
                                    Dirección
                                </small>
                            </h3>
                            <hr />

                            <div className="col-12">
                                <label >Dirección</label>
                                <input className="form-control " name="address"
                                    value={formik.values.address ? formik.values.address : ""} onChange={formik.handleChange} />
                                {formik.errors.address ? <div className="text-danger text-sm">{formik.errors.address}</div> : null}

                            </div>
                            <div className="col-6">

                                <label >Ciudad</label>
                                <input className="form-control " name="city"
                                    value={formik.values.city ? formik.values.city : ""} onChange={formik.handleChange} />
                                {formik.errors.city ? <div className="text-danger text-sm">{formik.errors.city}</div> : null}

                            </div>
                            <div className="col-6">
                                <label >Estado/Provincia</label>
                                <input className="form-control " name="state"
                                    value={formik.values.state ? formik.values.state : ""} onChange={formik.handleChange} />
                                {formik.errors.state ? <div className="text-danger text-sm">{formik.errors.state}</div> : null}

                            </div>

                            <div className="col-12">
                                <label >Pais</label>
                                <input className="form-control " name="country"
                                    value={formik.values.country ? formik.values.country : ""} onChange={formik.handleChange} />
                                {formik.errors.country ? <div className="text-danger text-sm">{formik.errors.country}</div> : null}

                            </div>

                            <p>&nbsp;</p>
                            <h3 className="text-uppercase margin">
                                <small>
                                    Contacto de Emergencia
                                </small>
                            </h3>
                            <hr />

                            <div className="col-6">
                                <label >Nombre</label>
                                <input className="form-control " name="emergencyContactName"
                                    value={formik.values.emergencyContactName ? formik.values.emergencyContactName : ""} onChange={formik.handleChange} />
                                {formik.errors.emergencyContactName ? <div className="text-danger text-sm">{formik.errors.emergencyContactName}</div> : null}

                            </div>
                            <div className="col-6">

                                <label >Parentesco</label>
                                <input className="form-control " name="emergencyContactRelationship"
                                    value={formik.values.emergencyContactRelationship ? formik.values.emergencyContactRelationship : ""} onChange={formik.handleChange} />
                                {formik.errors.emergencyContactRelationship ? <div className="text-danger text-sm">{formik.errors.emergencyContactRelationship}</div> : null}

                            </div>
                            <div className="col-6">
                                <label >Telefono</label>
                                <input className="form-control " name="emergencyContactPhone"
                                    value={formik.values.emergencyContactPhone ? formik.values.emergencyContactPhone : ""} onChange={formik.handleChange} />
                                {formik.errors.emergencyContactPhone ? <div className="text-danger text-sm">{formik.errors.emergencyContactPhone}</div> : null}

                            </div>

                            <div className="col-6">
                                <label >Telefono 2</label>
                                <input className="form-control " name="emergencyContactPhone2"
                                    value={formik.values.emergencyContactPhone2 ? formik.values.emergencyContactPhone2 : ""} onChange={formik.handleChange} />
                                {formik.errors.emergencyContactPhone2 ? <div className="text-danger text-sm">{formik.errors.emergencyContactPhone2}</div> : null}

                            </div>

                            <p>&nbsp;</p>
                            <h3 className="text-uppercase margin">
                                <small>
                                    Salud
                                </small>
                            </h3>
                            <hr />

                            <div className="col-12">
                                <label >Alergias</label>
                                <input className="form-control " name="allergies"
                                    value={formik.values.allergies ? formik.values.allergies : ""} onChange={formik.handleChange} />
                                {formik.errors.allergies ? <div className="text-danger text-sm">{formik.errors.allergies}</div> : null}
                            </div>

                            <div className="col-12">
                                <label >Medicinas</label>
                                <input className="form-control " name="medicines"
                                    value={formik.values.medicines ? formik.values.medicines : ""} onChange={formik.handleChange} />
                                {formik.errors.medicines ? <div className="text-danger text-sm">{formik.errors.medicines}</div> : null}
                            </div>
                            <div className="col-6">
                                <label >Aseguradora </label>
                                <select className="form-control" name="assuranceCompanyId" value={formik.values.assuranceCompanyId ? formik.values.assuranceCompanyId : ""} onChange={formik.handleChange}>
                                    <option value="">-</option>
                                    {insurances && insurances.map((item, index) => {
                                        return (<option value={item.id} key={index}>{item.name}</option>)
                                    })}
                                </select>

                                {formik.errors.assuranceCompanyId ? <div className="text-danger text-sm">{formik.errors.assuranceCompanyId}</div> : null}

                            </div>
                            <div className="col-6">
                                <label >Poliza de Seguro </label>
                                <input className="form-control " name="insurancePolicy"
                                    value={formik.values.insurancePolicy ? formik.values.insurancePolicy : ""} onChange={formik.handleChange} />
                                {formik.errors.insurancePolicy ? <div className="text-danger text-sm">{formik.errors.insurancePolicy}</div> : null}

                            </div>
                            <div className="col-6">
                                <label >Membresía de Poliza </label>
                                <input className="form-control " name="insuranceMembership"
                                    value={formik.values.insuranceMembership ? formik.values.insuranceMembership : ""} onChange={formik.handleChange} />
                                {formik.errors.insuranceMembership ? <div className="text-danger text-sm">{formik.errors.insuranceMembership}</div> : null}

                            </div>
                            <div className="col-6">
                                <label >Tipo de sangre </label>
                                <select className="form-control" id="bloodTypeId" value={formik.values.bloodTypeId ? formik.values.bloodTypeId : ""} onChange={formik.handleChange}>
                                    <option value="">-</option>
                                    {bloodTypes && bloodTypes.map((item, index) => {
                                        return (<option value={item.id} key={index}>{item.name}</option>)
                                    })}
                                </select>

                                {formik.errors.bloodTypeId ? <div className="text-danger text-sm">{formik.errors.bloodTypeId}</div> : null}

                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" type="submit" className="btn-sm"
                            disabled={state.IsLoading}
                        >
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default AddEditKid;