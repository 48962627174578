import React, { useEffect, useState } from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import { ProfileCardWidget, ChoosePhotoWidget } from '../Widgets';
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";

import api from '../../common/api';
import Parents from './Parents';
import UserProfileCard from './UserProfileCard';
import Kids from './Kids';
import { Routes } from '../../routes';
import ChangePassword from './ChangePassword';
const UserDetails = props => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentYear, setCurrentYear] = useState(null);
  const [state, setState] = useState({
    selectedUser: null,
    UI: {
      IsLoading: false, showChangePassword: false,
      SelectedUserForChange: null
    }
  })
  useEffect(() => {
    request();
  }, []);

  const request = async () => {
    let request = await api.getAction("users/Details", "id=" + props.match.params.id);
    console.log("request response", request);
    if (request.data.status === "ok") {
      setState({
        ...state,
        selectedUser : request.data.response
      });
      
    }

    //Get current year
    let requestYear = await api.getAction("Dashboard/General", "id=" + props.match.params.id);
    
    if (requestYear.data.status === "ok") {
      setCurrentYear(requestYear.data.response);
    }
  }

  const toggleChangePassword = obj => {

   setShowChangePassword(!showChangePassword);
    
    if (obj) {
      setState({
        ...state,
        UI:{
          ...state.UI,
          SelectedUserForChange: { UserId: obj.id, Password: "" }
        }
      })
      
    }
  }


  if (state.selectedUser === null) {
    return (<></>)
  }
  return (<Row>
    {showChangePassword ?
      <ChangePassword toggle={toggleChangePassword} show={showChangePassword} model={state.UI.SelectedUserForChange} />
      : null}
    <nav aria-label="breadcrumb" className="mb-2">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a href="/"><i className="fa fa-home"></i></a></li>

        <li className="breadcrumb-item"><a href={Routes.Users.path}>Usuarios</a></li>
        <li className="breadcrumb-item">Detalle</li>
      </ol>
    </nav>
    <Col xs={12} xl={4}>
      <Row>
        <Col xs={12}>
          <UserProfileCard User={state.selectedUser} toggleChangePassword={toggleChangePassword} />

        </Col>

      </Row>
    </Col>
    <Col xs={12} xl={8}>
      <Row>
        <Col xs={12}>
          <div className='card shadow-sm mb-4'>
            <div className='card-body text-center'>
              <button className='btn btn-secondary btn-lg'
              type='button' onClick={()=>{
                props.history.push(Routes.Inscription.path)
              }}
              >Inscribir Acampante</button>
            </div>
          </div>
          <Kids User={state.selectedUser.id} CurrentCampYear={currentYear} />
          <Parents User={state.selectedUser.id} />
        </Col>

      </Row>
    </Col>
  </Row>);
}

export default UserDetails;