
import React, { useEffect, useState } from "react";
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { Routes } from "../../routes";
import IslogedIn from "../../common/IsLogedIn";
import api from "../../common/api";

import CamperList from "../Campers/CamperList";
import { parsedUser } from "../../common/GetCurrentUser";


const DashboardOverview = ({ history }) => {
  const [state, setState] = useState({
    

  });
  const user = parsedUser();
  useEffect(() => {
    try{
    if (!IslogedIn() ) {

      history.push(Routes.SignIn.path);
    }
    if(!user || user === null){
      history.push(Routes.SignIn.path);
    }
    
    history.push(Routes.UserDetails.clean+user.ID);
  }catch(ex){
    console.error(ex);

    history.push(Routes.SignIn.path);
  }
    //bindingRemote();
  }, []);

  const bindingRemote = async () => {
    let query = "userid="+user.ID;
    let request = await api.getAction("users/listKids", query);
    console.log("request response", request);
    if (request.data.status === "ok") {
      setState(
        
         request.data.response,
        
      )

    }
  }

  const search = () => {
    let campers = state.CurrentYear.campers;
    let filtered = [];
    campers.forEach(subscription => {
      if (name.length > 0) {
        subscription.records = subscription.records.filter(x => x.firstName.includes(name));
      }
      filtered.push(subscription);
    });

    setState({
      ...state,
      Campers: filtered
    });
  }
  const [name, setName] = useState("");



  return (
    <>
     

      <Row className="justify-content-md-center">
        <Col xs={12} md={12} xl={12} className="mb-4">

          <CamperList history={history} />
        </Col>

      
      </Row>

    </>
  );
};


export default DashboardOverview;