import React, { useState } from 'react';

// import Profile1 from "../assets/img/team/profile-picture-1.jpg";
// import ProfileCover from "../assets/img/profile-cover.jpg";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import AddEditKid from './AddEditKid';
import api from '../../common/api';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../routes';
const Kids = (props) => {
    const [state, setState] = useState([]);

    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selectedKid, setSelectedKid] = useState(null);
    const toggleAddEdit = async (kidSelected, reload) => {
        setSelectedKid(kidSelected);
        setShowAddEdit(!showAddEdit);

        if (reload) {
            await requestRecord();
        }
    }

    const history = useHistory();
    const requestRecord = async () => {
        try {
            let query = "";


            if (props.User) {

                query += "UserId=" + props.User;
            }
            const request = await api.getAction(
                "users/ListKids", query
            );

            let response = request.data;

            if (response.status === "ok") {
                setState(response.response);
                //   state.SelectedFile.set(null);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    const recordsToList = () => {
        let obj = state;
        if (props.forInscription) {

            // if (props.CurrentCampYear) {
                
            //     obj = state.filter(x => x.kidCampYears.length == 0 );
            // }
        }
        return obj;
    }

    useEffect(() => {
        requestRecord();
    }, [])
    const deleteConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.name + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await api.getAction("users/DeleteKid", "id=" + obj.id);
                        await requestRecord();
                    }
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }
    return (
        <>
            {showAddEdit ?
                <AddEditKid model={selectedKid} toggle={toggleAddEdit} show={showAddEdit} /> : null
            }
            <Card border="light" className=" p-0 mb-4">

                <Card.Body className="table-responsive">

                    <Card.Title>Niños
                        <Button variant="primary" size="sm" className="mx-2" onClick={() => {
                            toggleAddEdit({
                                id: 0,
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                secondLastName: '',
                                address: '',
                                birthdate: '',
                                allergies: '',
                                assuranceCompanyId: null,
                                insuranceMembership: '',
                                baiguateroId: '',
                                bedweeting: null,
                                bloodTypeId: null,
                                city: '',
                                email: '',
                                country: '',
                                emergencyContactName: null,
                                emergencyContactPhone: null,
                                emergencyContactPhone2: '',
                                emergencyContactRelationShip: '',
                                state: '',
                                whoCanTakeTheKidOut: '',
                                genderId: null,
                                partnersNames: '',
                                knowToSwim: false,
                                medicines: '',
                                insurancePolicy: ''

                            });
                        }}>
                            <i className="fa fa-plus" /> Agregar
                        </Button>
                    </Card.Title>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Email</th>
                                <th>ID Baiguate</th>
                                <th>Edad</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {recordsToList().map((item, index) => {

                                let years =item.birthdate? moment().diff(item.birthdate.substring(0, 10), "years", false) :0;
                                return (<tr key={index}>
                                    <td>{item.firstName??""} {item.middleName??""} {item.lastName??""} {item.secondLastName??""}
                                        {props.forInscription ?
                                            <>
                                                <br />
                                                <button className="btn btn-sm btn-success mx-2 d-block d-md-none"
                                                    type="button"
                                                    onClick={() => {
                                                        props.selectTheKid(item);
                                                    }}>
                                                    Seleccionar
                                                </button>
                                            </>
                                            : 
                                            <>
                                            <br />
                                            <div className='btn-group'>
                                            {!props.forInscription && props.CurrentCampYear && item.kidCampYears 
                                            && item.kidCampYears.filter(x => x.campYearId === props.CurrentCampYear.id)[0] ?
                                                <button className='btn btn-sm btn-secondary'
                                                    onClick={() => {
                                                        let record = item.kidCampYears.filter(x => x.campYearId === props.CurrentCampYear.id)[0]
                                                        history.push(Routes.CamperDetails.clean + record.id);
                                                    }}>
                                                    Ver Inscripción
                                                </button> :
                                               !props.forInscription && props.CurrentCampYear && item.kidCampYears && !item.kidCampYears.filter(x => x.campYearId === props.CurrentCampYear.id)[0] ?
                                                    <button className='btn btn-sm btn-secondary'
                                                        onClick={() => {
                                                            history.push(Routes.Inscription.path);
                                                        }}
                                                    >
                                                        Inscribir
                                                    </button> : null
                                            }
                                            <button className='btn btn-sm btn-primary' type="button " onClick={() => {
                                                toggleAddEdit(item)
                                            }}>
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            <button className='btn btn-sm btn-danger' type="button " onClick={() => {
                                                deleteConfirm(item)
                                            }}>
                                                <i className='fa fa-trash'></i>
                                            </button>

                                        </div>
                                        </>
                                        }
                                    </td>
                                    <td>{item.email}</td>
                                    <td>{item.baiguateroId} </td>
                                    <td>{moment(item.birthdate).format("MMM DD, YYYY")} ({years} años)</td>
                                    <td className="text-right">
                                        {props.forInscription ?
                                            <button className="btn btn-sm btn-success mx-2"
                                                type="button"
                                                onClick={() => {
                                                    props.selectTheKid(item);
                                                }}>
                                                Seleccionar
                                            </button> : null
                                        }
                                       
                                    </td>

                                </tr>)
                            })}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        </>
    );
};

export default Kids;