import React, { useState } from "react";
import { Col, Row, Button, Dropdown } from '@themesberg/react-bootstrap';


import ExtraExpenses from './ExtraExpenses';
import { Routes } from "../../routes";
import api from "../../common/api";
import { useEffect } from "react";
import * as moment from "moment-timezone";
import Users from "../Users/Index";


export default (props) => {
    const [activeSection, setActiveSection] = useState("users");
    const [currentYear, setCurrentYear] = useState(null);

    useEffect(() => {
        requestCurrentYear();
    }, [])

    const requestCurrentYear = async () => {

        try {
            let query = "";
            if (props.Camper) {
                query += "CamperId=" + props.Camper;
            }

            if (props.User) {

                query += "UserId=" + props.User;
            }
            const request = await api.getAction(
                "Year/Current", query
            );

            let response = request.data;
            if (response.status === "ok") {
                setCurrentYear(response.response);
            }

        } catch (ex) {
            console.error(ex);
        }
    }
    return (
        <>

            <nav aria-label="breadcrumb" className="mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/"><i className="fa fa-home"></i></a></li>

                    <li className="breadcrumb-item"><a href={Routes.Settings.path}>Configuracion</a></li>

                </ol>
            </nav>

            <Row className="my-4">
                <Col xs={12}>
                    <h3>{currentYear && moment(currentYear.year).format("YYYY")}</h3>
                </Col>
                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>

                            <ul className="nav nav-pills nav-fill  flex-column my-4 mx-4">
                                <li className="nav-item "><button className={" btn-block btn  text-left " + (activeSection === "users" ? "btn-primary" : "btn")} onClick={() => setActiveSection("users")}>Usuarios</button></li>
                                <li className="nav-item "><button className={" btn-block btn  text-left " + (activeSection === "roles" ? "btn-primary" : "btn")} onClick={() => setActiveSection("roles")}>Roles</button></li>
                                <li className="nav-item "><button className={" btn-block btn  text-left " + (activeSection === "extraExpenses" ? "btn-primary" : "btn")} onClick={() => setActiveSection("extraExpenses")}>Gastos Extras</button></li>
                            </ul>
                        </Col>

                    </Row>
                </Col>
                <Col xs={12} xl={8}>

                    {activeSection === "users" ?
                        <div className="mb-4">
                            <Users />
                        </div> :
                        null}

                    {activeSection === "extraExpenses" ?
                        <div className="mb-4">
                            <ExtraExpenses currentYear={currentYear} />
                            {/*<Users Full />*/}
                        </div> :
                        null}


                </Col>


            </Row>
        </>
    );
};
