import { Button, Modal, Col, Row, Form } from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import api from "../../common/api";
import * as yup from 'yup';
import { parsedUser } from "../../common/GetCurrentUser";
//Validation
const validationSchema = yup.object({
    name: yup.string().required(),
    cost: yup.number().required(),
});

const AddEditExtraExpenses = props => {
    const [model, setModel] = useState(props.model);
    const [state, setState] = useState({
        IsLoading: false
    })

    useEffect(() => {

        setTimeout(() => {
            let focus = document.getElementById("Name");
            if (focus !== null) {
                focus.focus();

            }
        }, 1000);

    }, []);

    const handleSubmit = async (obj) => {

        try {
            setState({
                ...state,
                IsLoading: !state.IsLoading
            });

            const theUser = parsedUser();

            let query = {
                Id: theUser.ID,
                Data: obj
            }
            debugger;
            //We have to sent to api    
            const request = await api.postAction(
                'ExtraExpenses/AddEdit', query
            );



            if (request.data.status === "ok") {
                props.toggle(request.data.response, true);
            }
        } catch (ex) {
            console.error(ex);
        }
        finally {
            setState({
                ...state,
                IsLoading: !state.IsLoading
            });
        }

    }


    const formik = useFormik({
        initialValues: model,
        validationSchema,
        onSubmit: handleSubmit,
    })

    return (
        <>
            <Modal show={true} onHide={props.toggle} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Gasto Extra</Modal.Title>
                </Modal.Header>



                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body>

                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text"
                                    name="name" id="Name"
                                    value={formik.values.name} onChange={formik.handleChange} />
                                {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Label>Costo</Form.Label>
                                <Form.Control type="text"
                                    name="cost" id="cost"
                                    value={formik.values.cost} onChange={formik.handleChange} />
                                {formik.errors.cost ? <div className="invalid text-sm">{formik.errors.cost}</div> : null}
                            </Col>
                         

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" type="submit" className="btn-sm"
                            disabled={state.IsLoading}
                        >
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default AddEditExtraExpenses;