
import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faPrint, faCloudUploadAlt, faEllipsisV, faPlus, faRocket, faTasks, faTrash, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { Routes } from "../../routes";
import IslogedIn from "../../common/IsLogedIn";
import api from "../../common/api";
import { useState } from "@hookstate/core";
import Pagination from "../Common/Pagination";
import ChangePassword from "./ChangePassword";
const Users = ({ history }) => {
    const state = useState({
        Users: [],
        UI: {
            Quantity: 10,
            Page: 0,
            QuantityOfPages: 1,
            Search: "",
            showChangePassword: false
        }
    })
    useEffect(() => {
        if (!IslogedIn()) {

            history.push(Routes.SignIn.path);
        }
        Request();
    }, []);

    const Request = async () => {
        let query = "";
        query += "Page=" + state.UI.Page.value;
        query += "&Quantity=" + state.UI.Quantity.value;
        if (state.UI.Search.value.length > 0) {
            query += "&Search=" + state.UI.Search.value;
        }

        let request = await api.getAction("users/list", query);
        console.log("request response", request);
        if (request.data.status === "ok") {

            state.Users.set(request.data.response);
            state.UI.Page.set(request.data.page);
            state.UI.QuantityOfPages.set(request.data.quantityOfPages);
        }
    }

    const onPageChanged = async obj => {
        state.UI.Page.set(obj);
        await Request();
    }

    const toggleChangePassword = obj => {
        state.UI.showChangePassword.set(!state.UI.showChangePassword.value);
        state.UI.SelectedUser.set({ UserId: obj.id, Password: "" });
    }

    return (
        <>
            {/* <nav aria-label="breadcrumb" className="mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/"><i className="fa fa-home"></i></a></li>

                    <li className="breadcrumb-item"><a href={Routes.Users.path}>Usuarios</a></li>

                </ol>
            </nav> */}
            {state.UI.showChangePassword.value ?
                <ChangePassword toggle={toggleChangePassword} show={state.UI.showChangePassword.value} model={state.UI.SelectedUser.value} />
                : null}
            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                    <div className="card shadow-sm border-light">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mb-2">
                                    <h4 className="display-5">
                                        Usuarios
                                    </h4>
                                </div>
                                <div className="col-auto ml-auto text-right mb-2">
                                    <input type="text" className="form-control" value={state.UI.Search.value}
                                        placeholder="Busqueda"
                                        onChange={async e => {
                                            state.UI.Search.set(e.target.value);
                                            if (e.target.value.length > 2) {
                                                await Request()
                                            }
                                        }} />
                                </div>

                            </div>
                            {state.Users.length > 0 ?
                                JSON.parse(JSON.stringify(state.Users.value)).map((item, index) => {// state.CurrentYear.campers.map((item, index) => {
                                    return (
                                        <div className="row" key={index}>
                                            <div className="col-auto">
                                                {index + 1}
                                            </div>
                                            <div className="col-6 clickable" onClick={() => {
                                                history.push(Routes.UserDetails.clean + item.id)
                                            }}>
                                                {item.email}<br />
                                                <small className="text-muted">
                                                    Padres: {item.parents.length} |
                                                    Acampantes: {item.kids.length}
                                                </small>
                                            </div>


                                            <div className="col text-right">
                                                <Dropdown className="">
                                                    <Dropdown.Toggle as={Button} size="sm" className="me-2">
                                                        <FontAwesomeIcon icon={faEllipsisV} className="me-2" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                                                        <Dropdown.Item className="fw-bold" onClick={() => {
                                                            // history.push(Routes.CamperDetails.clean + item.id)
                                                        }}>
                                                            <FontAwesomeIcon icon={faTasks} className="me-2" /> Ver detalle
                                                        </Dropdown.Item>

                                                        <Dropdown.Item className="fw-bold" onClick={() => toggleChangePassword(item)}>
                                                            <FontAwesomeIcon icon={faPrint} className="me-2" /> Cambiar contraseña
                                                        </Dropdown.Item>

                                                        <Dropdown.Divider />

                                                        <Dropdown.Item className="fw-bold">
                                                            <FontAwesomeIcon icon={faTrash} className="text-danger me-2" /> Eliminar
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                            <div className="col-12">
                                                <hr />
                                            </div>
                                        </div>
                                    )
                                })
                                : null}
                        </div>
                        <div className="card-footer">
                            <Pagination totalPages={state.UI.QuantityOfPages.value} currentPage={state.UI.Page.value} onPageChanged={onPageChanged} />
                        </div>
                    </div>
                </Col>


            </Row>

        </>
    );
};


export default Users;