import { Button, Modal, Col, Row, Form } from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import api from "../../common/api";
import * as yup from 'yup';
import { parsedUser } from "../../common/GetCurrentUser";
//Validation
const validationSchema = yup.object({
    name: yup.string().required(),
    identifier: yup.string().required(),
});

const AddEditParent = props => {
    const [model, setModel] = useState(props.model);
    const [state, setState] = useState({
        IsLoading: false
    })

    useEffect(() => {

        setTimeout(() => {
            let focus = document.getElementById("Name");
            if (focus !== null) {
                focus.focus();

            }
        }, 1000);

    }, []);

    const handleSubmit = async (obj) => {

        try {
            setState({
                ...state,
                IsLoading: !state.IsLoading
            });

            const theUser = parsedUser();

            let query = {
                Id: theUser.ID,
                Data: obj
            }
            debugger;
            //We have to sent to api    
            const request = await api.postAction(
                'Users/AddEditParent', query
            );



            if (request.data.status === "ok") {
                props.toggle(request.data.response, true);
            }
        } catch (ex) {
            console.error(ex);
        }
        finally {
            setState({
                ...state,
                IsLoading: !state.IsLoading
            });
        }

    }


    const formik = useFormik({
        initialValues: model,
        validationSchema,
        onSubmit: handleSubmit,
    })

    return (
        <>
            <Modal show={true} onHide={props.toggle} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Padre/Tutor</Modal.Title>
                </Modal.Header>



                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body>

                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text"
                                    name="name" id="Name"
                                    value={formik.values.name} onChange={formik.handleChange} />
                                {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Label>Segundo Nombre</Form.Label>
                                <Form.Control type="text"
                                    name="middleName" id="middleName"
                                    value={formik.values.middleName} onChange={formik.handleChange} />
                                {formik.errors.middleName ? <div className="invalid text-sm">{formik.errors.middleName}</div> : null}
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control type="text"
                                    name="lastName" id="lastName"
                                    value={formik.values.lastName} onChange={formik.handleChange} />
                                {formik.errors.lastName ? <div className="invalid text-sm">{formik.errors.lastName}</div> : null}
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Label>Segundo Apellido</Form.Label>
                                <Form.Control type="text"
                                    name="secondLastName" id="secondLastName"
                                    value={formik.values.secondLastName} onChange={formik.handleChange} />
                                {formik.errors.secondLastName ? <div className="invalid text-sm">{formik.errors.secondLastName}</div> : null}
                            </Col>
                            <Col xs={12} md={12}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                    name="email" id="email"
                                    value={formik.values.email} onChange={formik.handleChange} />
                                {formik.errors.email ? <div className="invalid text-sm">{formik.errors.email}</div> : null}
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control type="text"
                                    name="phone" id="phone"
                                    value={formik.values.phone} onChange={formik.handleChange} />
                                {formik.errors.phone ? <div className="invalid text-sm">{formik.errors.phone}</div> : null}
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Label>Telefono 2</Form.Label>
                                <Form.Control type="text"
                                    name="phone2" id="phone2"
                                    value={formik.values.phone2} onChange={formik.handleChange} />
                                {formik.errors.phone2 ? <div className="invalid text-sm">{formik.errors.phone2}</div> : null}
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Label>Telefono 3</Form.Label>
                                <Form.Control type="text"
                                    name="phone3" id="phone3"
                                    value={formik.values.phone3} onChange={formik.handleChange} />
                                {formik.errors.phone3 ? <div className="invalid text-sm">{formik.errors.phone3}</div> : null}
                            </Col>
                            <Col xs={12} md={12}>
                                <Form.Label>Cédula/Pasaporte</Form.Label>
                                <Form.Control type="text"
                                    name="identifier" id="identifier"
                                    value={formik.values.identifier} onChange={formik.handleChange} />
                                {formik.errors.identifier ? <div className="invalid text-sm">{formik.errors.identifier}</div> : null}
                            </Col>
                            <Col xs={12} md={12}>
                                <Form.Label>Dirección</Form.Label>
                                <Form.Control type="text"
                                    name="address" id="address"
                                    value={formik.values.address} onChange={formik.handleChange} />
                                {formik.errors.address ? <div className="invalid text-sm">{formik.errors.address}</div> : null}
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" type="submit" className="btn-sm"
                            disabled={state.IsLoading}
                        >
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default AddEditParent;