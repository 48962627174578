
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../../data/notifications";
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";
import { Routes } from "../../routes";
import GetCurrentUser from "../../common/GetCurrentUser";
import AddEditProject from "../Projects/AddEditProject";
import { parseJwt } from "../../common/helpers";


export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const [user, setUser] = useState(null);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const [showAddEditProject, setShowAddEditProject] = useState(false);
  useEffect(() => {
    let theUser = parseJwt(GetCurrentUser());
    if (theUser === null) {

      props.history.push(Routes.SignIn.path)
    }
    setUser(theUser);

    console.log("the user", user);
  }, []);
  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };


  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <>
      {showAddEditProject ?
        <AddEditProject toggle={() => setShowAddEditProject(!showAddEditProject)} show={showAddEditProject} /> :
        null}
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 d-print-none">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar d-none">
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
            <Nav className="align-items-center">
              {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
                <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faBell} className="bell-shake" />
                    {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                  <ListGroup className="list-group-flush">
                    <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                      Notifications
                    </Nav.Link>

                    {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                    <Dropdown.Item className="text-center text-primary fw-bold py-3">
                      View all
                    </Dropdown.Item>
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown> */}

              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    {user && user.Email?
                    <Image src={ "https://via.placeholder.com/40/3f67fa/ffffff/?text=" + user.Email.substring(0, 1)} className="user-avatar md-avatar rounded-circle" />
                    :null}
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{user && user.Email}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold" onClick={() => props.history.push(Routes.UserDetails.clean+user.ID)}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Mi Perfil
                  </Dropdown.Item>

                  {/* <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                  </Dropdown.Item> */}
                  <Dropdown.Item className="fw-bold" as="button" onClick={() => {
                    setShowAddEditProject(true);
                  }}>
                    <FontAwesomeIcon icon={faUserShield} className="me-2" /> Soporte
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold"
                    onClick={() => {
                      props.history.push(Routes.SignIn.path)
                    }}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Cerrar Sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
