import { Modal} from "@themesberg/react-bootstrap";

import React, { useEffect, useState } from "react";
import api from "../../common/api";
import * as yup from 'yup';
import { FormatMoney } from "../../common/helpers";


const AddEditExtraExpense = props => {
    const [model, setModel] = useState(props.model);
    const [expenses, setExpenses] = useState([]);

    useEffect(() => {

        request();

    }, []);

    const request = async () => {
        var apiRequest = await api.getAction("extraExpenses/List");
        if (apiRequest.data.status === "ok") {
            setExpenses(apiRequest.data.response);
        }
    }
    const addHandler = async (obj)=>{
        let query = "KidCampYearId="+model+"&ExtraExpenseId="+obj.id;
        var apiRequest = await api.getAction("extraExpenses/AddExpenseToCamper", query);
        if (apiRequest.data.status === "ok") {
            props.toggle( true);
        }
    }

    return (
        <>
            <Modal show={true} onHide={props.toggle} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Gastos Extra</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-stripped">
                        <tbody>
                {expenses && expenses.map((item, index)=>{
                    return (<tr key={index}>
                        <td>{item.name}</td>
                        <td className="text-right">
                            {FormatMoney( item.cost)}
                        </td>
                        <td className="text-right">
                            <button className="btn btn-primary btn-sm"
                            onClick={()=>{
                                addHandler(item);
                            }}
                            >
                                Agregar
                            </button>
                        </td>
                    </tr>)
                })}
                </tbody>
                    </table>

                </Modal.Body>


            </Modal>
        </>
    )
}

export default AddEditExtraExpense;