import { Form, Modal } from "@themesberg/react-bootstrap";

import React, { useEffect, useState } from "react";
import api from "../../common/api";
import * as yup from 'yup';
import { FormatMoney } from "../../common/helpers";
import { useFormik } from "formik";


const AddEditEducation = props => {
    const [model, setModel] = useState(props.model);
    

    useEffect(() => {

        

    }, []);
    const handleSubmit = async (obj, event) => {

        //Now we are sending to the server
        let query =`kidid=${obj.kidid}&school=${obj.schoolName}&grade=${obj.grade}`;
        let request = await api.getAction(
            "campers/editeducation",query
        );

        if (request.data.status === "ok") {
            
            props.toggle();
            window.location.reload();
        }
    };
    const formik = useFormik({
        initialValues: {
            schoolName: model.school? model.school.name: "",
            grade: model.grade,
            kidid: model.kidId,
            id: model.id
        }
        ,
       // validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <Modal show={true} onHide={props.toggle} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Educación </Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 col-xs-6">
                                <label>Colegio</label>

                                <input className="form-control text-box single-line"
                                    name="schoolName"
                                    value={formik.values.schoolName ? formik.values.schoolName : ""}
                                    onChange={formik.handleChange} />
                                {formik.errors.schoolName ? <div className="text-danger text-sm">{formik.errors.schoolName}</div> : null}
                            </div>
                            <div className="col-md-6 col-xs-6">
                                <label>Curso </label>
                                <select className='form-control' name="grade"
                                    value={formik.values.grade ? formik.values.grade : ""}
                                    onChange={formik.handleChange} >
                                    <option value="">-</option>
                                    <optgroup label='Nivel Primario'>
                                        <option value="Primaria-Primer Grado">Primer Grado</option>
                                        <option value="Primaria-Segundo Grado">Segundo Grado</option>
                                        <option value="Primaria-Tercer Grado">Tercer Grado</option>
                                        <option value="Primaria-Cuarto Grado">Cuarto Grado</option>
                                        <option value="Primaria-Quinto Grado">Quinto Grado</option>
                                        <option value="Primaria-Sexto Grado">Sexto Grado</option>
                                    </optgroup>
                                    <optgroup label='Nivel Secundaria'>
                                        <option value="Secundaria-Primer Grado">Primer Grado</option>
                                        <option value="Secundaria-Segundo Grado">Segundo Grado</option>
                                        <option value="Secundaria-Tercer Grado">Tercer Grado</option>
                                        <option value="Secundaria-Cuarto Grado">Cuarto Grado</option>
                                        <option value="Secundaria-Quinto Grado">Quinto Grado</option>
                                        <option value="Secundaria-Sexto Grado">Sexto Grado</option>
                                    </optgroup>
                                </select>

                                {formik.errors.grade ? <div className="text-danger text-sm">{formik.errors.grade}</div> : null}
                                <br />
                                <small>NOTA: CURSO ACTUAL</small>
                            </div></div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" type="submit">
                            Guardar
                        </button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default AddEditEducation;