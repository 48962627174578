import moment from 'moment';

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

//check name renderer
export const NameRenderer = nameObj => {
    console.log("obj name", nameObj);
    let name = "";
    if (nameObj.lastName) {
        name += nameObj.lastName;
    }
    if (nameObj.secondLastName) {
        name += (name.length > 0 ? " " : "") + nameObj.secondLastName;
    }
    if (nameObj.firstName) {
        name += (name.length > 0 ? ", " : "") + nameObj.firstName;
    }
    if (nameObj.middleName) {
        name += (name.length > 0 ? " " : "") + nameObj.middleName;
    }


    return name.trim();
}

//Show filtered list by camper type
export const FilterList = (list, type)=>{
    let filteredList = [];
    if(type ==="kids"){
        filteredList = list.filter(x=> 
            moment().diff(x.kid.birthdate.substring(0, 10), "years", false) <14 );
    }else{
        filteredList = list.filter(x=> 
            moment().diff(x.kid.birthdate.substring(0, 10), "years", false) >=14 );
    }

    console.log("filtered list", filteredList);
    return filteredList;
}



//n= number
//c= comma separator
//t = houndry separator
//
export const FormatMoney = function (n, c, decimal, t) {

    var c = isNaN(c = Math.abs(c)) ? 2 : c,
      decimal = decimal == undefined ? "." : decimal,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? decimal + Math.abs(n - i).toFixed(c).slice(2) : "");
  };
  
  
  export const NumberFormat = function (item) {
    return new Intl.NumberFormat().format(isNaN(item * 1) ? 0 : item * 1)
  }
  